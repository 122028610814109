import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container } from "../../components";

import { blogPosts } from "./articles";
import "swiper/scss";

import styles from "./Blog.module.scss";

// import linkedin from "../../assets/images/linkedin.svg";
// import twitter from "../../assets/images/twitter.svg";
// import letter from "../../assets/images/letter.svg";
// import phone from "../../assets/images/phone.svg";
import { Footer } from "../../components/Footer";
import classNames from "classnames";

const Blog = () =>
{
  return (
    <section className={ classNames( styles.blog, "scale" ) }>
      <Container>
        <h1 className={ styles.title }>Blog & resources</h1>

        <div className={ styles.slides }>
          <Swiper
            breakpoints={ {
              0: {
                slidesPerView: 1.5,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              800: {
                slidesPerView: 2.5,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 2.5,
                spaceBetween: 2,
              },
            } }
          >
            { blogPosts.map( ( b, i ) => (
              <SwiperSlide key={ i }>
                <article className={ styles.article }>
                  <div className={ styles.image }>
                    <img src={ b.img } alt={ `Blog cover for ${ b.title }` } />
                  </div>

                  <div className={ styles.content }>
                    <h3 className={ styles.label }>{ b.title }</h3>

                    <p className={ styles.date }>{ b.date }</p>
                  </div>
                </article>
              </SwiperSlide>
            ) ) }
          </Swiper>
        </div>
      </Container>

      {/* <div className={styles.wrapper}>
        <Container className={styles.row}>
          <a
            href="https://wa.link/v88hjq"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.chat}
          >
            We are just one call away
          </a>

          <div className={styles.icons}>
            <div className={styles.icon}>
              <img src={phone} alt={`${phone} icon`} />
            </div>
            <div className={styles.icon}>
              <img src={letter} alt={`${letter} icon`} />
            </div>

            <div className={styles.icon}>
              <img src={linkedin} alt={`${linkedin} icon`} />
            </div>
            <div className={styles.icon}>
              <img src={twitter} alt={`${twitter} icon`} />
            </div>
          </div>
        </Container>
      </div>

      <footer>
        <Container>
          <p>INRIGUE EXPRESSIONS 2022</p>
        </Container>
      </footer> */}
      <Footer
        stylee={ { position: "absolute", bottom: "0", width: "100%" } }
        stylee2={ styles.extraPadding }
      />
    </section>
  );
};

export { Blog };
