import rocket from '../assets/images/rocket.svg';
import docs from '../assets/images/docs.svg';
import flag from '../assets/images/flag.svg';
import coins from '../assets/images/coins.svg';
import shield from '../assets/images/shield.svg';
import money from '../assets/images/money.svg';


export const solutions =
    [
        {
            title: "Getting Started ?",
            text1Content: [
                { text: "Start a new company in 5 minutes or register your business" },
                { text: "Get a bank account" },
                {
                    text: "Get a sandbox license to pilot regulated products and services or apply for a new license",
                },
            ],
            text2Content: [
                { text: "Obtain a Tax ID" },
                { text: "Get a nominee or in-country representative" },
                { text: "Get residence or work permit" },
                { text: "Accept payments from any parts of Africa compliantly" },
            ],
            icon: rocket
        },
        {
            title: "Regulatory Intelligence",
            text1Content: [
                { text: "Get regulatory updates tailored to your unique needs in your inbox" },
                { text: "Understand requlatory terrain in Africa" }
            ],
            text2Content: [
                { text: "Professional advice one call away" },
                { text: "Never miss a filing. Integrate our obligations register with your calendar for free" }
            ],
            icon: docs
        },
        {
            title: "Bespoke Compliance",
            text1Content: [
                { text: "Evaluating and assessment of compliance program and recommending remediation/treatment action" },
                { text: "Take self paced trainings in compliance with relevant regulatory requirements" },
                {
                    text: "Providing advice on compliance obligations; etc.",
                },
            ],
            text2Content: [
                { text: "Obtain a Tax id" },
                { text: "Get a nominee or in-country representative" },
                { text: "Get residence or work permit" },
                { text: "Accept payments from any parts of Africa compliantly" },
            ],
            icon: flag
        },
        {
            title: "Transactions Advisory",
            text1Content: [
                { text: "Conducting due diligence on targets and providing reports as and when due" },
                { text: "Providing support on transaction strategies that best meet your goals" }
            ],
            text2Content: [
                { text: "Advising on legal and regulatory issues arising from the transaction" },
                { text: "Helping you navigate the complexities of a transaction one advice at a time" },
            ],
            icon: coins
        },
        {
            title: "Data Protection",
            text1Content: [
                { text: "Privacy Management" },
                {
                    text: "Data Discovery and Classification",
                },
            ],
            text2Content: [
                { text: "Guidance on Data research" },
                { text: "Information Security Management" },
            ],
            icon: shield
        },
        {
            title: "Anti Money Laundering",
            text1Content: [
                { text: "Compliance assessment and independent audits" },
                {
                    text: "Conduct of AML/CFT Trainings in accordance with extant regulations",
                },
            ],
            text2Content: [
                { text: "Hiring/ Secondment recommendations for your compliance team" },
                { text: "Advising on AML/CFT compliance issues" },
                { text: "Internal control documentation" },
            ],
            icon: money
        },
    ];
