import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Container } from "../Container";
import { A } from "../Pressable/A";
import { Menu } from '../Icons';
import styles from './NavBar.module.scss';
import logo from "../../assets/images/logo.png";

const NavBar = (
    {
        visible,
        jumpTo,
        setVisible,
        isLilac,
        isBlack,
        isWhite,
        isFirst
    }
) =>
{

    const [ open, setOpen ] = useState( false );
    const [ about, setAbout ] = useState( 0 );


    const toggle = () =>
    {
        setOpen( s => !s );
    };


    const handleJump = index =>
    {
        setOpen( false );
        jumpTo( index );
    };

    useEffect( () =>
    {

        const fade = () =>
        {
            if ( visible )
            {
                setVisible( false );
                setOpen( false );
            }
        };

        const timer = setTimeout( fade, 2600 );

        return () =>
        {
            clearTimeout( timer );
        };
    }, [ visible, setVisible ] );

    useEffect( () =>
    {
        const getAboutIndex = () =>
        {
            setAbout( window.innerWidth < 1024 ? 8 : 5 );
        };

        getAboutIndex();
        window.addEventListener( "resize", getAboutIndex );

        return () => window.removeEventListener( "resize", getAboutIndex );
    }, [] );



    return (
        <nav
            onMouseEnter={ () => setVisible( true ) }
            onMouseLeave={ () => setVisible( false ) }
            className={ classNames( styles.nav,
                {
                    [ styles.visible ]: isFirst || visible,
                    [ styles.lilac ]: isLilac,
                    [ styles.black ]: isBlack,
                    [ styles.white ]: isWhite,
                } ) }>
            <Container className={ styles.wrapper }>

                <div className={ styles.mobile }>
                    <button className={ styles.toggle } >
                        <Menu />
                    </button>

                    <button className={ styles.logo } >
                        
                        <img src={ logo } alt="Regcompass logo" />
                    </button>

                    <ul
                        onMouseEnter={ () => setVisible( true ) }
                        onMouseLeave={ () => setVisible( false ) }
                        className={ classNames( styles.links,
                            {
                                [ styles.open ]: open
                            } ) }>
                        <li>
                            <button>
                              <a className="" href="#home">Home</a> 
                            </button>
                        </li>
                        <li>
                            <button>
                            <a className="" href="#resources">Resources</a>
                            </button>
                        </li>
                        <li>
                            <button>
                              <a className="" href="#solutions">Solutions</a>
                            </button>
                        </li>
                        <li>
                            <button>
                              <a className="" href="#about">About Us</a>
                            </button>
                        </li>

                        <li>
                            <A className={ styles.start } to="/start">
                                Let's get you started
                            </A>
                        </li>
                    </ul>
                </div>
                <div className={ styles.large }>
                    <button className={ styles.logo }>
                        <a className="" href="#home">
                         <img src={ logo } alt="Regcompass logo" />
                        </a> 
                    </button>

                    <ul
                        onMouseEnter={ () => setVisible( true ) }
                        className={styles.links}>
                        <li>
                            <button >
                              <a className="" href="#home">Home</a> 
                            </button>
                        </li>
                        <li>
                            <button>
                              <a className="" href="#resources">Resources</a>
                            </button>
                        </li>
                        <li>
                            <button>
                              <a className="" href="#solutions">Solutions</a>
                            </button>
                        </li>
                        <li>
                            <button>
                              <a className="" href="#about">About Us</a>
                            </button>
                        </li>


                    </ul>

                    <div>
                        <A className={ styles.start } to="/start">
                            Let's get you started
                        </A>
                    </div>
                </div>

            </Container>
        </nav>
    );
};

export { NavBar };
