export const faqs = [
    {
        id: 1,
        question: "Why should you trust Regcompass with your firm's projects?",
        answer:
            <div>
                <p> A good lawyer knows where to find the law and advises sufficiently on it.
                </p>

                <br />

                <p>
                    The best tech lawyers do not only know where to find the law but how the technology works and how the law can apply to the specifics of the technology.
                </p>

                <br />

                <p>                At Regcompass, you get the best tech lawyers with proven experience in several legal, business and regulatory ramifications of the startup environment.
                </p>
            </div>,
    },
    {
        id: 2,
        question:
            "What services does Regcompass provide?",
        answer:
            <div>

                <p>
                    We provide a wide range of services broadly categorized under legal advisory, business advisory, corporate commercial, technology consulting and regulatory compliance services.
                </p>

                <br />

                <p>


                    For further information about our services, view our solutions page or speak to one of our staff here.
                </p>
            </div>,
    },
    {
        id: 3,
        question:
            "Is Regcompass a law firm?",
        answer:
            <div>

                <p>
                    Regcompass is a firm of lawyers and compliance professionals with considerable Information Technology (IT) experience.
                </p>
                <br />

                <p>
                    Even though our staff are legally trained personnel, we offer much wider services than the traditional law firm.</p>

                <br />

                <p>
                    We see the business and technology aspect of things and we provide advice to meet our clients’ needs in that respect.</p>
            </div>,
    },
    {
        id: 4,
        question:
            "What is the payment structure for Regcompass’ services?",
        answer:
            <div>

                <p>
                  Our pricing is flexible and bespoke. We work with clients based on their needs.  </p>
                <br />

                <p>We charge either on case by case basis, hourly, on retainer or contingent upon event occurrence of defined events.<br/> It's as simple and transparent as can be. </p>

            </div>,
    },
    {
        id: 5,
        question:
            "In which African countries is Regcompass currently functional?",
        answer:
            <div>
                <p>
                    We currently have fully operational personnels in Nigeria, Ghana and Kenya.
                </p>

                <br />

                <p>
                    While we are working hard to expand our base of professionals in other business friendly African countries, we have made significant partnerships in these jurisdictions to handle your business efficiently.</p>
            </div>,
    },
    {
        id: 6,
        question:
            "Is Regcompass duly registered with any regulatory authority?",
        answer:
            <div>

                <p>
                    Our attorneys are members of the bar associations of their respective African countries and licensed to practice within their respective jurisdictions.
                </p>

            </div>,
    },
];
