import React from 'react';
import { Container } from '../../components';

import { whatYouGet } from "../../utils";

import styles from './WhatYouGet.module.scss';


const WhatYouGet = () =>
{
  return (
    <section className={ styles.what }>
      <Container>
        <h3 className={ styles.title } >
          What you get with <span className={ styles.purple }>regcompass </span>
        </h3>

        <div className={ styles.grid }>
          {
            whatYouGet.map( ( card, i ) => (
              <article
                className={ styles.card }
                key={ i } >
                <div className={ styles.arrow } />
                <p>{ card }</p>
              </article>

            ) )

          }

          <article className={ styles.more }>
            <p>Many More</p>
          </article>
        </div>
      </Container>
    </section>
  );
};

export { WhatYouGet };
