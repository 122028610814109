import React from 'react';
import
{
    Container,
    A,
    Map,
    Button
} from '../../components';
import styles from './Home.module.scss';


const Home = () =>
{
    return (
        <header className={ styles.hero }>
            <div className={ styles.map } >
                <Map />
            </div>
            <Container className={ styles.wrapper }>



                <h3 className={ styles.headerText }>
                    <span className={ styles.purple } >Powering</span> Regulated
                    <span className={ styles.purple } > Businesses </span>
                    in Africa
                </h3>

                <p className={ styles.motto }>
                    We are a one-stop shop for your regulatory compliance needs in Africa.

                    <span className={ styles.purple }>
                        Compliance in a box</span>
                </p>

                <section className={ styles.ctas }>
                    <a href="https://wa.link/04hgpe" style={{width:50, height:45, backgroundColor:"rgb(127, 28, 202)" , borderRadius: 5, color: "white", display:"flex" , justifyContent:"center", alignItems:"center"}} >
                        Contact us
                                             
                    </a>
                    <A href="/faq"
                        className={ styles.black }
                    >Learn more</A>

                </section>


            </Container>
        </header>
    );
};

export { Home };
