import React from 'react';
import { Container } from '../../components';

import styles from './Testimonial.module.scss';
import man from '../../assets/images/testimonials.png';
// import logoimg1 from "../../assets/images/logoimg1.png";
// import logoimg2 from "../../assets/images/logoimg2.png";
// import logoimg3 from "../../assets/images/logoimg3.png";
// import logoimg4 from "../../assets/images/logoimg4.png";
// import logoimg5 from "../../assets/images/logoimg5.png";

const Testimonial = () =>
{
    return (
        <section className={ styles.testimonial }>
            <Container className={ styles.wrapper }>
                <h1 className={ styles.title }>TESTIMONIALS</h1>

                <article className={ styles.content }>

                    <div className={ styles.text }>
                        <p>
                            An assessment of the impact of new regulations on your business is pinpointed and how other market players and regulators would react.  Regulations and policies in need of review are also recognized.

                        </p>

                        <p>
                            <strong>Tanimola John-Doe </strong>  <br />
                            Country Director, <br />
                            Ofofo Africa.
                        </p>
                    </div>

                    <div className={ styles.image }>
                        <div className={ styles.extra } />

                        <div className={ styles.imgWrap }>
                            <img src={ man } alt="a smiling man with a ball" />
                        </div>
                    </div>

                </article>
            </Container>

            {/* <section className={ styles.logos }>
                <h1 className={ styles.title }>TRUSTED CLIENTELLE</h1>

                <div className={ styles.clients }>
                    <div>
                        <img src={ logoimg1 } alt={ "Trade x logo" } />
                    </div>
                    <div>
                        <img src={ logoimg2 } alt={ "Astropay logo" } />
                    </div>
                    <div>
                        <img src={ logoimg4 } alt={ "Paga logo" } />
                    </div>
                    <div>
                        <img src={ logoimg3 } alt={ "Paystack logo" } />
                    </div>
                    <div>
                        <img src={ logoimg5 } alt={ "Helios logo" } />
                    </div>
                </div>
            </section> */}
        </section>
    );
};

export { Testimonial };
