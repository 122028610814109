import React from 'react';
import classNames from 'classnames';

import { A } from '../../../components';

import { solutions } from '../../../utils';

import styles from './SolutionsCard.module.scss';


const SolutionsCard = ( { index, className } ) =>
{
    const card = solutions[ index ];

    return (
        <article className={ classNames( styles.card, className ) }>

            <div className={ styles.icon }>
                <img src={ card.icon } alt={ `${ card.title } icon` } />
            </div>

            <h4 className={ styles.title }>{ card.title }</h4>

            <div className={ styles.grid } >
                <ul className={ styles.list }>
                    {
                        card.text1Content.map( item => (
                            <li key={ item.text }>
                                <p>{
                                    item.text
                                }</p>

                            </li>
                        ) )
                    }
                </ul>
                <ul className={ styles.list }>
                    {
                        card.text2Content.map( item => (
                            <li key={ item.text }>
                                <p>{
                                    item.text
                                }</p>

                            </li>
                        ) )
                    }
                </ul>
            </div>

            <A to="/start">
                Let's get started
            </A>
        </article>
    );
};

export { SolutionsCard };
