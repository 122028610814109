import { useState, useEffect } from "react";
import { NavBar2 } from "../components";
import { Container } from "../components";
import styles from "./Privacy.module.scss";
import { Element, Link } from "react-scroll";
import { Footer } from "../components/Footer";

const Privacy = () =>
{
  const [ activeSelector, setActiveSelector ] = useState( 1 );
  const [ offSetValue, setOffSetValue ] = useState( 0 );
  const Selectors = [
    "Privacy Notice",
    "Summary",
    "Information We Collect",
    "What we do with the data collected",
    "Legal basis for using your information",
    "Consent",
    "Contract",
    "Legitimate Interests",
    "Transfer of data",
    "Your rights",
    "Third parties",
    "Cookies",
    "How long do we store your data?",
    "Where do we store the data?",
    "Security of data",
    "Changes to Our Privacy Notice",
    "Contact us",
    "Back to Top",
  ];

  useEffect( () =>
  {
    const computeOffset = () =>
    {
      if ( window.innerWidth > 700 )
      {
        setOffSetValue( -150 );
      }
      if ( window.innerWidth <= 320 )
      {
        setOffSetValue( -420 );
      }
      if ( window.innerWidth <= 375 && window.innerWidth > 320 )
      {
        setOffSetValue( -400 );
      }
      if ( window.innerWidth <= 650 && window.innerWidth > 375 )
      {
        setOffSetValue( -380 );
      }
    };

    computeOffset();
    window.addEventListener( "resize", computeOffset );

    return () => window.removeEventListener( "resize", computeOffset );
  }, [] );

  // useEffect( () =>
  // {
  //   document.body.style.maxHeight = "unset";
  //   document.body.style.maxWidth = "unset";
  //   document.body.style.overflow = "unset";
  // }, [] );

  return (
    <>
      <NavBar2 />
      <Element name="Back to Top"></Element>
      <Container className={ styles.wrapper }>
        <div className={ `${ styles.parent }` }>
          <div className={ styles.selectorParent }>
            { Selectors.map( ( data, index ) =>
            {
              return (
                <Link
                  to={ data }
                  smooth={ true }
                  duration={ 1000 }
                  offset={ offSetValue }
                >
                  <div
                    key={ index }
                    className={ ` ${ activeSelector === index + 1
                      ? styles.isActive
                      : styles.selector
                      }` }
                    onClick={ () =>
                    {
                      setActiveSelector( index + 1 );
                    } }
                  >
                    { data }
                  </div>
                </Link>
              );
            } ) }
          </div>
          <div className={ styles.content }>
            <div>
              <Element name="Privacy Notice">
                <h1>Privacy Notice</h1>
                <p>
                  Regcompass Consults (Regcompass) is a regulatory, legal and
                  business advisory firm that powers regulated business across
                  Africa by providing bespoke compliance, legal and business
                  advisory services to technology companies particularly
                  startups- with focus on compliance, data protection,
                  intellectual property, cybersecurity &#38; startup advisory.
                  Regcompass respects your privacy concerns and this Privacy
                  Notice explains how we handle your personal data and our
                  attitude towards data privacy and protection. For the purposes
                  of this Privacy Notice, “personal data” means any information
                  relating to an identified or identifiable individual.
                </p>
              </Element>
            </div>
            <div>
              <Element name="Summary">
                <p className={ styles.contentHeader }>Summary</p>
                <ul>
                  <li>
                    Like we stated in the preliminary above, we respect your
                    privacy concerns and we will always honour your data privacy
                    rights;
                  </li>
                  <li>
                    We will be lying to you if we say we do not use your data.
                    We do! But it is to provide services to you, to fulfill our
                    legal obligation, respond to your enquiries, and manage our
                    relationship with you;
                  </li>
                  <li>
                    We only keep data that is required and delete it when no
                    longer necessary;
                  </li>
                  <li> We do not honor third party requests on our website.</li>
                </ul>
              </Element>
            </div>
            <div>
              <Element name="Information We Collect">
                <p className={ styles.contentHeader }>Information We Collect</p>
                <p>
                  Regcompass collects and processes personal data relating to
                  Users in relation to the services we provide. The data we
                  process differs depending on your interactions with us, as
                  detailed fully below.
                </p>{ " " }
                <p>
                  The expression “User” (and “you”/”your”) in this Privacy
                  Notice shall mean any person accessing the Regcompass website,
                  using Regcompass products and services, or about whom we
                  collect and use any information in the course of providing our
                  products and services.
                </p>
                <p>If you are a Client</p>
                <ul>
                  <li>Your name and contact information;</li>
                  <li>Information about your business activities with us;</li>
                  <li>
                    Information about your enquiries and our service offerings;
                  </li>
                  <li>
                    Data from contract which may include: details of the
                    contract, third party providers, counterparty names and
                    email addresses, comments, activity on contracts, signatures
                  </li>
                </ul>
                <p>Your name and contact information;</p>
                <ul>
                  <li>Your name and contact information;</li>
                  <li>Information about your business activities with us;</li>
                  <li>Information about your enquiries and our products</li>
                </ul>
                <p>If you are a business partner</p>
                <ul>
                  <li>Your name and contact information;</li>
                  <li>Partnership onboarding information provided to us.</li>
                </ul>
                <p>If you are an prospective employee</p>
                <ul>
                  <li>Information provided during recruitment process</li>
                </ul>
                <p>If you a visitor</p>
                <ul>
                  <li>
                    We keep log files generated from our servers, this include
                    the IP addressed assigned to you.
                  </li>
                </ul>
              </Element>
            </div>
            <div>
              <Element name="What we do with the data collected">
                <p className={ styles.contentHeader }>
                  What we do with the data collected
                </p>
                <p>
                  Regcompass uses the data collected from Users to perform
                  services requested by you, respond to your enquiries, customer
                  support, improve our services ,improve our products, contact
                  you about change to our website or products and manage our
                  relationship with you.
                </p>
                <p>
                  If you register for our regulatory updates, we will only
                  contact you to send the updates to you.
                </p>
              </Element>
            </div>
            <div>
              <Element name="Legal basis for using your information">
                <p className={ styles.contentHeader }>
                  Legal basis for using your information
                </p>
                <p>
                  We rely on appropriate legal basis in accordance with the
                  extant data protection laws. In appropriate circumstances we
                  rely on performance of contract and legal obligation, our
                  legitimate interests, consent, compliance with a mandatory
                  legal obligation, your vital interest and public interest.
                  When it is appropriate.
                </p>
              </Element>
            </div>
            <div>
              <Element name="Consent">
                <p className={ styles.contentHeader }>Consent</p>
                <p>
                  You accept this Privacy Notice when you give consent upon
                  access to our platforms, or use our services, features,
                  technologies or functions offered on our website, digital
                  platforms or visit any of our offices for official or
                  non-official purposes. This Privacy Notice governs the use of
                  Regcompass&apos; services and intervention projects by our
                  users and stakeholders, unless otherwise agreed through a
                  written contract.
                </p>
                <p>
                  You have the right to refuse to consent or withdraw your
                  consent at any time by contacting us at hello@regcompass.com
                </p>

                <p>
                  However, we should mention that consent withdrawal will not
                  affect the legality of any processing carried out before you
                  withdraw your consent.
                </p>
              </Element>
            </div>
            <div>
              <Element name="Consent">
                <p className={ styles.contentHeader }>Consent</p>
                <p>
                  You accept this Privacy Notice when you give consent upon
                  access to our platforms, or use our services, features,
                  technologies or functions offered on our website, digital
                  platforms or visit any of our offices for official or
                  non-official purposes. This Privacy Notice governs the use of
                  Regcompass&apos; services and intervention projects by our
                  users and stakeholders, unless otherwise agreed through a
                  written contract.
                </p>
                <p>
                  You have the right to refuse to consent or withdraw your
                  consent at any time by contacting us at hello@regcompass.com
                </p>

                <p>
                  However, we should mention that consent withdrawal will not
                  affect the legality of any processing carried out before you
                  withdraw your consent.
                </p>
              </Element>
            </div>
            <div>
              <Element name="Contract">
                <p className={ styles.contentHeader }>Contract</p>
                <p>
                  Processing your data is necessary for a contract you have with
                  us, or because we have asked you to take specific steps before
                  entering into that contract.
                </p>
              </Element>
            </div>
            <div>
              <Element name="Legitimate Interests">
                <p className={ styles.contentHeader }>Legitimate Interests</p>
                <p>
                  Processing your data is sometimes necessary for our legitimate
                  interests or the legitimate interests of a third party,
                  provided those interests are not outweighed by your rights and
                  interests. These legitimate interests are:
                </p>
                <ul>
                  <li>Delivering, developing and improving our website</li>
                  <li>
                    Enabling us to enhance, customise or modify our services and
                    communication
                  </li>
                  <li>Determining whether marketing campaigns are effective</li>
                  <li>Enhancing data security</li>
                </ul>
                <p>
                  In each case, these legitimate interests are only valid if
                  they are not outweighed by your rights and interests.
                </p>
              </Element>
            </div>
            <div>
              <Element name="Transfer of data">
                <p className={ styles.contentHeader }>Transfer of data</p>
                <p>
                  Data collected by Regcompass may be transferred outside
                  Nigeria. We rely on the appropriate data transfer mechanism or
                  any of its relevant exception to transfer data outside
                  Nigeria. We would ensure appropriate safeguard before
                  transferring data outside Nigeria. We also ensure that data is
                  not transferred outside the whitelisted countries as
                  stipulated by NITDA.
                </p>
              </Element>
            </div>
            <div>
              <Element name="Your rights">
                <p className={ styles.contentHeader }>Your rights</p>
                <p>
                  Every data subject has You have data protection rights. The
                  following are your rights:
                </p>
                <ul>
                  <li>
                    The right to request for access to your Personal Data; The
                    right to request that we erase your Personal Data if it is
                    no longer valid or necessary for the purposes for which it
                    was collected or if it is incomplete;
                  </li>{ " " }
                  <li>
                    The right to rectify or amend inaccurate or incomplete
                    Personal Data;
                  </li>{ " " }
                  <li>
                    The right to object to processing of your Personal Data if
                    there are compelling legitimate grounds to do so and to the
                    extent permitted by law or regulation;
                  </li>{ " " }
                  <li>
                    The right to portability of data. You can request we move
                    your personal data in a used and machine-readable format to
                    another Data Controller. We would where it is technically
                    possible;
                  </li>{ " " }
                  <li>
                    The right to lodge a complaint with the Court, National
                    Information Technology Development Agency (NITDA) or any
                    other relevant supervisory authority.
                  </li>
                </ul>
              </Element>
            </div>
            <div>
              <Element name="Third parties">
                <p className={ styles.contentHeader }>Third parties</p>
                <p>
                  Generally, we have a no transfer policy and we do not transfer
                  your data to any third party or entertain in any third party
                  request through our website. However, we may share your data
                  with third parties if:
                </p>
                <ul>
                  <li>If we have to complete a contract on your behalf;</li>

                  <li>If you give your explicit instructions and consent;</li>

                  <li>
                    If there is a legal obligation on us to share such data
                    under existing laws and regulations;
                  </li>

                  <li>
                    There is court judgement, injunction or any other binding
                    legal directive.
                  </li>
                </ul>
              </Element>
            </div>
            <div>
              <Element name="Cookies">
                <p className={ styles.contentHeader }>Cookies</p>
                <p>
                  A cookie is a small piece of data that a website – when
                  visited by a User – asks your browser to store on your device
                  in order to remember information about you, such as your
                  language preference etc. We use cookies to track and better
                  understand your preferences for a more accurate User
                  experience on our website. The cookies we use are necessary
                  for the website to function and cannot be switched off in our
                  systems. They are usually only set in response to actions made
                  by you which amount to a request for services, such as setting
                  your privacy preferences, logging in or filling in forms. You
                  can set your browser to block or alert you about these
                  cookies, but some parts of the site will not then work. These
                  cookies do not store any personally identifiable information.
                </p>
              </Element>
            </div>
            <div>
              <Element name="How long do we store your data?">
                <p className={ styles.contentHeader }>
                  How long do we store your data?
                </p>
                <p>
                  We retain your data for the duration of our relationship.
                  Also, we retain data, subject to relevant provisions of
                  applicable laws. Upon the expiration of our relationship, we
                  would archive and stop actively using any personal data about
                  you within 6 months from the termination of our relationship.
                </p>
              </Element>
            </div>
            <div>
              <Element name="Where do we store the data?">
                <p className={ styles.contentHeader }>
                  Where do we store the data?
                </p>
                <p>
                  The personal data we collect is processed at our offices in
                  Nigeria and in any data processing facilities operated by the
                  third parties identified below:
                </p>
                <p>
                  If we transfer or store your information outside Nigeria in
                  this way, we will take steps to ensure that your privacy
                  rights continue to be protected as outlined in this Privacy
                  Notice.{ " " }
                </p>
                <p>
                  The following services keep us running by storing or
                  processing your data on our behalf:
                </p>
              </Element>
            </div>
            <div>
              <Element name="Security of data">
                <p className={ styles.contentHeader }>Security of data</p>
                <p>
                  We are very particular about preserving your privacy and
                  protecting your data. Therefore, to avoid the loss, theft,
                  misuse and unauthorised access, disclosure, alteration, and
                  destruction of your information, we have put in place a range
                  of administrative, technical, organisational and physical
                  safeguards while in Regcompass&apos; possession or control.
                  The security measures that we have implemented include, but
                  are not limited to:
                </p>
                <ul>
                  <li>Encryption of Personal Data during transmission;</li>
                  <li>
                    Prevention of accidental or unauthorized access, reading,
                    copying, modification, removal or destruction of the
                    Personal Data;
                  </li>
                  <li>
                    Access is restricted to Regcompass&apos; employees who have
                    a “need to know” in order to carry out the purpose of
                    providing you with the requested service and all of those
                    employees are under an obligation of confidentiality with
                    Regcompass;  Training of Regcompass&apos; employees
                    regarding the proper handling of Personal Data;
                  </li>
                  <li>
                    Use of technological means (e.g. firewalls) to prevent
                    unauthorized access to the Personal Data while it is in
                    Regcompass&apos; possession or control;
                  </li>
                  <li>
                    Logging of all processing activity of the Personal Data
                    which records when the Personal Data was processed, what
                    processes the Personal Data underwent, anyone that accessed
                    the Personal Data, where and when the Personal Data was
                    transmitted and when the Personal Data was erased from
                    Regcompass; and
                  </li>
                  <li>
                    Annual review and evaluation of the adequacy of
                    Regcompass&apos; security measures.
                  </li>
                </ul>
              </Element>
            </div>
            <div>
              <Element name="Changes to Our Privacy Notice">
                <p className={ styles.contentHeader }>
                  Changes to Our Privacy Notice
                </p>
                <p>
                  We reserve the right to amend or update this Privacy Notice at
                  any time. We may or may not notify you of such changes,
                  however, you can always view the most current version of this
                  Privacy Notice at any time at: www.regcompass.com. The date of
                  the last revision appears at the end of this policy. Once the
                  revision is effective, your use of our services and your data
                  will be governed by the most recently revised Privacy Notice.
                  If you do not want to be bound by the changes to the Privacy
                  Notice, you may revoke your consent by contacting us as
                  described above and ask that we delete your data from our
                  database.
                </p>
              </Element>
            </div>
            <div>
              <Element name="Contact us">
                <p>Contact us</p>
                <p className={ styles.contentHeader }>hello@regcompass.com </p>
              </Element>
            </div>
          </div>
        </div>
      </Container>
      <Footer stylee={ { position: "relative", bottom: "0", width: "100%" } } />
    </>
  );
};

export default Privacy;
