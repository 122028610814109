import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

import { Container } from "../Container";
import { A } from "../Pressable/A";
import { Menu } from "../Icons";

import styles from "./NavBar.module.scss";
import logo from "../../assets/images/logo.png";

const NavBar3 = () => {
  const [open, setOpen] = useState(false);
  const [about, setAbout] = useState(0);

  const toggle = () => {
    setOpen((s) => !s);
  };

  useEffect(() => {
    const getAboutIndex = () => {
      setAbout(window.innerWidth < 1024 ? 8 : 5);
    };

    getAboutIndex();
    window.addEventListener("resize", getAboutIndex);

    return () => window.removeEventListener("resize", getAboutIndex);
  }, []);

  return (
    <nav
      className={classNames(styles.nav, styles.visible, styles.white)}
      style={{ position: "relative" }}
    >
      <Container className={styles.wrapper}>
        <div className={styles.mobile}>
          <button className={styles.toggle} onClick={toggle}>
            <Menu />
          </button>

          <NavLink to="/" className={styles.logo}>
            <img src={logo} alt="Regcompass logo" />
          </NavLink>

          <ul
            className={classNames(styles.links, {
              [styles.open]: open,
            })}
          >
            <li>
              <NavLink className={styles.link} to="/?section=0">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink className={styles.link} to="/?section=1">
                Resources
              </NavLink>
            </li>
            <li>
              <NavLink className={styles.link} to="/?section=3">
                Solutions
              </NavLink>
            </li>
            <li>
              <NavLink className={styles.link} to={`/?section=${about}`}>
                About Us
              </NavLink>
            </li>

            <li>
              <A className={styles.start} to="/start">
                Let's get you started
              </A>
            </li>
          </ul>
        </div>
        <div className={styles.large}>
          <NavLink to="/" className={styles.logo}>
            <img src={logo} alt="Regcompass logo" />
          </NavLink>

          <ul className={styles.links}>
            <li>
              <NavLink className={styles.link} to="/?section=0">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink className={styles.link} to="/?section=1">
                Resources
              </NavLink>
            </li>
            <li>
              <NavLink className={styles.link} to="/?section=3">
                Solutions
              </NavLink>
            </li>
            <li>
              <NavLink className={styles.link} to={`/?section=${about}`}>
                About Us
              </NavLink>
            </li>
          </ul>

          <div>
            <A className={styles.start} to="/start">
              Let's get you started
            </A>
          </div>
        </div>
      </Container>
    </nav>
  );
};

export { NavBar3 };
