// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (min-width: 1700px) {\n  .Container_container__MM274 {\n    margin: auto;\n    max-width: 1600px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/Container/Container.module.scss"],"names":[],"mappings":"AAEI;EAFJ;IAIQ,YAAA;IACA,iBAAA;EADN;AACF","sourcesContent":[".container\n{\n    @media screen and (min-width:1700px) \n    {        \n        margin: auto;\n        max-width: 1600px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Container_container__MM274"
};
export default ___CSS_LOADER_EXPORT___;
