import classNames from 'classnames';
import React from 'react';

import styles from './Pressable.module.scss';


const Button = (
    {
        children,
        className,
        type = "button",
        ...props
    }
) =>
{
    return (
        <button
            className={ classNames( styles.pressable, className ) }
            type={ type }
            { ...props }>
            { children }
        </button>
    );
};

export { Button };
