import data from "./animation.json";

import image_0 from "./images/image_0.png";
import image_1 from "./images/image_1.png";
import image_2 from "./images/image_2.png";
import image_3 from "./images/image_3.png";
import image_4 from "./images/image_4.png";
import image_5 from "./images/image_5.png";
import image_6 from "./images/image_6.png";
import image_7 from "./images/image_7.png";
import image_8 from "./images/image_8.png";
import image_9 from "./images/image_9.png";
import image_10 from "./images/image_10.png";
import image_11 from "./images/image_11.png";
import image_12 from "./images/image_12.png";
import image_13 from "./images/image_13.png";

const animationData = { ...data };

const assets = [
    {
        "id": "image_0",
        "w": 96,
        "h": 96,
        "u": "",
        "p": image_0,
        "e": 0
    },
    {
        "id": "image_1",
        "w": 803,
        "h": 778,
        "u": "",
        "p": image_1,
        "e": 0
    },
    {
        "id": "image_2",
        "w": 96,
        "h": 96,
        "u": "",
        "p": image_2,
        "e": 0
    },
    {
        "id": "image_3",
        "w": 96,
        "h": 96,
        "u": "",
        "p": image_3,
        "e": 0
    },
    {
        "id": "image_4",
        "w": 96,
        "h": 96,
        "u": "",
        "p": image_4,
        "e": 0
    },
    {
        "id": "image_5",
        "w": 96,
        "h": 96,
        "u": "",
        "p": image_5,
        "e": 0
    },
    {
        "id": "image_6",
        "w": 96,
        "h": 96,
        "u": "",
        "p": image_6,
        "e": 0
    },
    {
        "id": "image_7",
        "w": 96,
        "h": 96,
        "u": "",
        "p": image_7,
        "e": 0
    },
    {
        "id": "image_8",
        "w": 96,
        "h": 96,
        "u": "",
        "p": image_8,
        "e": 0
    },
    {
        "id": "image_9",
        "w": 96,
        "h": 96,
        "u": "",
        "p": image_9,
        "e": 0
    },
    {
        "id": "image_10",
        "w": 96,
        "h": 96,
        "u": "",
        "p": image_10,
        "e": 0
    },
    {
        "id": "image_11",
        "w": 904,
        "h": 440,
        "u": "",
        "p": image_11,
        "e": 0
    },
    {
        "id": "image_12",
        "w": 1049,
        "h": 982,
        "u": "",
        "p": image_12,
        "e": 0
    },
    {
        "id": "image_13",
        "w": 1512,
        "h": 982,
        "u": "",
        "p": image_13,
        "e": 0
    },
    {
        "id": "comp_0",
        "layers": [
            {
                "ddd": 0,
                "ind": 1,
                "ty": 0,
                "nm": "togo",
                "refId": "comp_1",
                "sr": -0.27622926829268,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            717.25,
                            512.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 821.061458587646,
                "op": 804.061458587646,
                "st": 821.062831878662,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 2,
                "ty": 0,
                "nm": "togo",
                "refId": "comp_1",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            717.25,
                            512.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 743.020114079856,
                "op": 804.563194235965,
                "st": 743.020114079856,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 3,
                "ty": 0,
                "nm": "malawi",
                "refId": "comp_2",
                "sr": -0.27622926829268,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            934,
                            665.75,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 742.506465911865,
                "op": 725.506465911865,
                "st": 742.507832050323,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 4,
                "ty": 0,
                "nm": "malawi",
                "refId": "comp_2",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            934,
                            665.75,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 664.465125587912,
                "op": 726.008205744021,
                "st": 664.465125587912,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 5,
                "ty": 0,
                "nm": "sa",
                "refId": "comp_3",
                "sr": -0.27622926829268,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            875.5,
                            784.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 662.450408935547,
                "op": 645.450408935547,
                "st": 662.451764345169,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 6,
                "ty": 0,
                "nm": "sa",
                "refId": "comp_3",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            875.5,
                            784.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 584.409086360452,
                "op": 645.952166516562,
                "st": 584.409086360452,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 7,
                "ty": 0,
                "nm": "mad",
                "refId": "comp_4",
                "sr": -0.27622926829268,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            937.5,
                            762.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 584.896087646484,
                "op": 567.896087646484,
                "st": 584.897496700287,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 8,
                "ty": 0,
                "nm": "mad",
                "refId": "comp_4",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            937.5,
                            762.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 506.854798358851,
                "op": 568.39787851496,
                "st": 506.854798358851,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 9,
                "ty": 0,
                "nm": "ethiopia",
                "refId": "comp_5",
                "sr": -0.27622926829268,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            955.5,
                            493.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 505.340423583984,
                "op": 488.340423583984,
                "st": 505.341825485229,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 10,
                "ty": 0,
                "nm": "ethiopia",
                "refId": "comp_5",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            955.5,
                            493.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 427.299109376564,
                "op": 488.842189532673,
                "st": 427.299109376564,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 11,
                "ty": 0,
                "nm": "niger",
                "refId": "comp_6",
                "sr": -0.27622926829268,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            775.5,
                            434.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 429.787559509277,
                "op": 412.787559509277,
                "st": 429.788947105408,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 12,
                "ty": 0,
                "nm": "niger",
                "refId": "comp_6",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            775.5,
                            434.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 351.746222355649,
                "op": 413.289302511758,
                "st": 351.746222355649,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 13,
                "ty": 0,
                "nm": "ghana",
                "refId": "comp_7",
                "sr": -0.27622926829268,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            717.25,
                            512.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 352.733631134033,
                "op": 335.733631134033,
                "st": 352.735025882721,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 14,
                "ty": 0,
                "nm": "ghana",
                "refId": "comp_7",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            717.25,
                            512.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 274.692284599219,
                "op": 336.235364755329,
                "st": 274.692284599219,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 15,
                "ty": 0,
                "nm": "kenya",
                "refId": "comp_8",
                "sr": -0.27622926829268,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            955.25,
                            537.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 265.672702789307,
                "op": 248.672702789307,
                "st": 265.674076080322,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 16,
                "ty": 0,
                "nm": "kenya",
                "refId": "comp_8",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            955.25,
                            537.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 187.631341939358,
                "op": 249.174422095467,
                "st": 187.631341939358,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 17,
                "ty": 0,
                "nm": "erithea",
                "refId": "comp_9",
                "sr": -0.27622926829268,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            933.75,
                            436,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 183.114895820618,
                "op": 166.114895820618,
                "st": 183.116276264191,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 18,
                "ty": 0,
                "nm": "erithea",
                "refId": "comp_9",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            933.75,
                            436,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 105.07355148604,
                "op": 166.61663164215,
                "st": 105.07355148604,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 19,
                "ty": 0,
                "nm": "nigeria",
                "refId": "comp_10",
                "sr": -0.27622926829268,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 111.564810276031,
                "op": 94.5648102760315,
                "st": 111.566194295883,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 20,
                "ty": 0,
                "nm": "nigeria",
                "refId": "comp_10",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 33.5234664264986,
                "op": 95.0665465826078,
                "st": 33.5234664264986,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 23,
                "ty": 4,
                "nm": "Shape Layer 224",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 265.186,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 266.396,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 267.606,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 268.817,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 270.028,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 271.481,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 272.691,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 273.901,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 275.112,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 276.565,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 277.776,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 278.986,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 280.196,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 743.02,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 744.23,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 745.441,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 746.652,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 747.863,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 749.315,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 750.525,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 751.736,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 752.947,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 754.399,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 755.61,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 756.82,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 758.030548095703,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            968.25,
                            527.562,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 22.015,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 27.0188287563013,
                                "s": [
                                    77,
                                    77,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.477124202953,
                                        0,
                                        0.858823529412,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 22.0154107875513,
                "op": 806.564595216652,
                "st": 22.0154107875513,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 24,
                "ty": 0,
                "nm": "scale fade 2",
                "refId": "comp_11",
                "sr": 1.21442708333333,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            968.125,
                            530.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            1007.125,
                            507.5,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            95,
                            95,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 755.028519963975,
                "op": 806.564595216652,
                "st": 755.028519963975,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 25,
                "ty": 0,
                "nm": "scale fade 2",
                "refId": "comp_11",
                "sr": 1.21442708333333,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            968.125,
                            530.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            1007.125,
                            507.5,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            95,
                            95,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 278.695086560592,
                "op": 349.244471129791,
                "st": 278.695086560592,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 26,
                "ty": 4,
                "nm": "Shape Layer 222",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 584.409,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 585.619,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 586.83,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 588.041,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 589.251,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 590.704,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 591.914,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 593.125,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 594.335,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 595.788,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 596.999,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 598.209,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 599.419784545898,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1125.375,
                            800.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 20.014,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 25.0174277756148,
                                "s": [
                                    77,
                                    77,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.477124202953,
                                        0,
                                        0.858823529412,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 20.0140098068648,
                "op": 806.564595216652,
                "st": 20.0140098068648,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 27,
                "ty": 0,
                "nm": "scale fade 2",
                "refId": "comp_11",
                "sr": 1.21442708333333,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1125.625,
                            802.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            1007.125,
                            507.5,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            95,
                            95,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 592.414690283198,
                "op": 662.964074852397,
                "st": 592.414690283198,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 28,
                "ty": 4,
                "nm": "Shape Layer 113",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1145.5,
                            380.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 18.013,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 23.0160267949283,
                                "s": [
                                    77,
                                    77,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.477124202953,
                                        0,
                                        0.858823529412,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 18.0126088261783,
                "op": 806.564595216652,
                "st": 18.0126088261783,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 29,
                "ty": 4,
                "nm": "Shape Layer 119",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 181.627,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 182.838,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 184.048,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 185.259,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 186.469,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 187.922,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 189.132,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 190.343,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 191.554,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 193.006,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 194.217,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 195.427,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 196.63781072532,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1204.875,
                            550.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 15.511,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 20.5142755690702,
                                "s": [
                                    77,
                                    77,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.477124202953,
                                        0,
                                        0.858823529412,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 15.5108576003202,
                "op": 806.564595216652,
                "st": 15.5108576003202,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 30,
                "ty": 0,
                "nm": "scale fade 2",
                "refId": "comp_11",
                "sr": 1.21442708333333,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1205.125,
                            553,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            1007.125,
                            507.5,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            95,
                            95,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 186.130291203843,
                "op": 256.679675773041,
                "st": 186.130291203843,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 31,
                "ty": 4,
                "nm": "Shape Layer 116",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 351.246,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 352.456,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 353.667,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 354.877,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 356.088,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 357.541,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 358.751,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 359.961,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 361.172,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 362.625,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 363.835,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 365.046,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 366.256399154663,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1026.75,
                            448.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 14.01,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 19.0132248335554,
                                "s": [
                                    77,
                                    77,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.477124202953,
                                        0,
                                        0.858823529412,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 14.0098068648054,
                "op": 806.564595216652,
                "st": 14.0098068648054,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 32,
                "ty": 0,
                "nm": "scale fade 2",
                "refId": "comp_11",
                "sr": 1.21442708333333,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1027.125,
                            450.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            1007.125,
                            507.5,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            95,
                            95,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 362.253577504253,
                "op": 432.802962073451,
                "st": 362.253577504253,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 33,
                "ty": 4,
                "nm": "Shape Layer 221",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 505.354,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 506.564,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 507.775,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 508.985,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 510.196,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 511.649,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 512.859,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 514.069,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 515.28,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 516.733,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 517.943,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 519.154,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 520.36434173584,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1264.125,
                            720.562,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 12.008,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 17.0118238528689,
                                "s": [
                                    77,
                                    77,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.477124202953,
                                        0,
                                        0.858823529412,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 806.564595216652,
                "st": 12.0084058841189,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 34,
                "ty": 0,
                "nm": "scale fade 2",
                "refId": "comp_11",
                "sr": 1.21442708333333,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1264.375,
                            723,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            1007.125,
                            507.5,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            95,
                            95,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 511.357950565396,
                "op": 581.907335134594,
                "st": 511.357950565396,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 35,
                "ty": 4,
                "nm": "Shape Layer 223",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 28.52,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 29.73,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 30.941,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 32.152,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 33.362,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 34.815,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 36.025,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 37.236,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 38.446,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 39.899,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 41.11,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 42.32,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 43.5304424034324,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 10.007,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 15.0104228721824,
                                "s": [
                                    88,
                                    88,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.477124202953,
                                        0,
                                        0.858823529412,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 10.0070049034324,
                "op": 806.564595216652,
                "st": 10.0070049034324,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 36,
                "ty": 0,
                "nm": "scale fade",
                "refId": "comp_12",
                "sr": 1.21442708333333,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 35.0245171620134,
                "op": 117.582307615331,
                "st": 35.0245171620134,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 37,
                "ty": 4,
                "nm": "Shape Layer 220",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 660.963,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 662.173,
                                "s": [
                                    0.04
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 663.384,
                                "s": [
                                    99.96
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 664.594,
                                "s": [
                                    0.04
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 665.805,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 667.258,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 668.468,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 669.678,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 670.889,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 672.342,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 673.552,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 674.763,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 675.973377227783,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1184.875,
                            675.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 8.006,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 13.0090218914959,
                                "s": [
                                    77,
                                    77,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.477124202953,
                                        0,
                                        0.858823529412,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 8.00560392274592,
                "op": 806.564595216652,
                "st": 8.00560392274592,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 38,
                "ty": 0,
                "nm": "scale fade 2",
                "refId": "comp_11",
                "sr": 1.21442708333333,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1185.125,
                            677.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            1007.125,
                            507.5,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            95,
                            95,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 668.968277794456,
                "op": 739.517662363655,
                "st": 668.968277794456,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 39,
                "ty": 4,
                "nm": "Shape Layer 117",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 111.078,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 112.288,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 113.498,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 114.709,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 115.92,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 117.373,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 118.583,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 119.793,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 121.004,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 122.457,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 123.668,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 124.878,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 126.088187317059,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1184.875,
                            448.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 6.004,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 11.0076209108094,
                                "s": [
                                    77,
                                    77,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.477124202953,
                                        0,
                                        0.858823529412,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 6.00420294205944,
                "op": 806.564595216652,
                "st": 6.00420294205944,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 40,
                "ty": 0,
                "nm": "scale fade 2",
                "refId": "comp_11",
                "sr": 1.21442708333333,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1185.125,
                            450.75,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            1007.125,
                            507.5,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            95,
                            95,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 113.079155408786,
                "op": 183.628539977985,
                "st": 113.079155408786,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 41,
                "ty": 4,
                "nm": "Shape Layer 118",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 427.8,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 429.01,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 430.22,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 431.431,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 432.642,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 434.095,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 435.305,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 436.515,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 437.726,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 439.179,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 440.389,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 441.599,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 442.809934616089,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1224.375,
                            493.562,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 4.003,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 9.00621993012296,
                                "s": [
                                    77,
                                    77,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.477124202953,
                                        0,
                                        0.858823529412,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 4.00280196137296,
                "op": 806.564595216652,
                "st": 4.00280196137296,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 42,
                "ty": 0,
                "nm": "scale fade 2",
                "refId": "comp_11",
                "sr": 1.21442708333333,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1224.875,
                            495.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            1007.125,
                            507.5,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            95,
                            95,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "w": 1512,
                "h": 982,
                "ip": 435.805063544481,
                "op": 506.35444811368,
                "st": 435.805063544481,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 43,
                "ty": 4,
                "nm": "Shape Layer 115",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            927.75,
                            436.812,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 2.001,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 7.00481894943648,
                                "s": [
                                    77,
                                    77,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.477124202953,
                                        0,
                                        0.858823529412,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 2.00140098068648,
                "op": 806.564595216652,
                "st": 2.00140098068648,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 44,
                "ty": 4,
                "nm": "Shape Layer 114",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1026.5,
                            379.812,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 0,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 5.00341796875,
                                "s": [
                                    77,
                                    77,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.477124202953,
                                        0,
                                        0.858823529412,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 45,
                "ty": 4,
                "nm": "Shape Layer 6",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            968.25,
                            527.562,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            77,
                            77,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 46,
                "ty": 4,
                "nm": "Shape Layer 5",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            88,
                            88,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 47,
                "ty": 4,
                "nm": "Shape Layer 12",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1125.375,
                            800.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            77,
                            77,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 48,
                "ty": 4,
                "nm": "Shape Layer 11",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1264.125,
                            720.562,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            77,
                            77,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 49,
                "ty": 4,
                "nm": "Shape Layer 10",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1184.875,
                            675.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            77,
                            77,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 50,
                "ty": 4,
                "nm": "Shape Layer 9",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1204.875,
                            550.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            77,
                            77,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 51,
                "ty": 4,
                "nm": "Shape Layer 8",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1224.375,
                            493.562,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            77,
                            77,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 52,
                "ty": 4,
                "nm": "Shape Layer 7",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1184.875,
                            448.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            77,
                            77,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 53,
                "ty": 4,
                "nm": "Shape Layer 4",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1026.75,
                            448.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            77,
                            77,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 54,
                "ty": 4,
                "nm": "Shape Layer 3",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            927.75,
                            436.812,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            77,
                            77,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 55,
                "ty": 4,
                "nm": "Shape Layer 2",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1026.5,
                            379.812,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            77,
                            77,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 56,
                "ty": 4,
                "nm": "Shape Layer 1",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1145.5,
                            380.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            77,
                            77,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        0.796078491211,
                                        0,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 57,
                "ty": 2,
                "nm": "Rectangle 104.png",
                "cl": "png",
                "refId": "image_11",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1060,
                            220,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            452,
                            220,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 58,
                "ty": 2,
                "nm": "Rectangle 103.png",
                "cl": "png",
                "refId": "image_12",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            524.5,
                            491,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            524.5,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 59,
                "ty": 2,
                "nm": "waee.png",
                "cl": "png",
                "refId": "image_13",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 60,
                "ty": 1,
                "nm": "White Solid 1",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            756,
                            491,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "sw": 1512,
                "sh": 982,
                "sc": "#ffffff",
                "ip": 0,
                "op": 806.564595216652,
                "st": 0,
                "bm": 0
            }
        ]
    },
    {
        "id": "comp_1",
        "layers": [
            {
                "ddd": 0,
                "ind": 1,
                "ty": 5,
                "nm": "TOGO",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1139.285,
                            463.045,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 8.19999980926514,
                                    "f": "Mulish-Black",
                                    "t": "TOGO",
                                    "j": 0,
                                    "tr": 25,
                                    "lh": 9.83999977111816,
                                    "ls": 0,
                                    "fc": [
                                        0.153,
                                        0.153,
                                        0.153
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 1,
                                "rn": 1,
                                "sh": 1,
                                "e": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 19.514,
                                            "s": [
                                                100
                                            ]
                                        },
                                        {
                                            "t": 29.5206644651256,
                                            "s": [
                                                0
                                            ]
                                        }
                                    ],
                                    "ix": 2
                                },
                                "r": 1
                            },
                            "a": {
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 2,
                "ty": 5,
                "nm": "Secure a cost-effective compliance product to set up shop and operate in any African country",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1118.75,
                            485.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "hasMask": true,
                "masksProperties": [
                    {
                        "inv": false,
                        "mode": "a",
                        "pt": {
                            "a": 0,
                            "k": {
                                "i": [
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ]
                                ],
                                "o": [
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ]
                                ],
                                "v": [
                                    [
                                        166.25,
                                        -14.5
                                    ],
                                    [
                                        9.75,
                                        -14.5
                                    ],
                                    [
                                        -10.25,
                                        5.5
                                    ],
                                    [
                                        -10.25,
                                        16.5
                                    ],
                                    [
                                        9.75,
                                        36.5
                                    ],
                                    [
                                        166.25,
                                        36.5
                                    ],
                                    [
                                        186.25,
                                        16.5
                                    ],
                                    [
                                        186.25,
                                        5.5
                                    ]
                                ],
                                "c": true
                            },
                            "ix": 1
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 3
                        },
                        "x": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                        },
                        "nm": "Mask 1"
                    }
                ],
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 10,
                                    "f": "Mulish-Regular",
                                    "t": "Secure a cost-effective\rcompliance product to set up shop\rand operate in any African country",
                                    "j": 0,
                                    "tr": 10,
                                    "lh": 12,
                                    "ls": 0,
                                    "fc": [
                                        0.463,
                                        0.262,
                                        0.522
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 50,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 3,
                                "rn": 0,
                                "sh": 2,
                                "o": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 20.014,
                                            "s": [
                                                -100
                                            ]
                                        },
                                        {
                                            "t": 41.0142099469629,
                                            "s": [
                                                100
                                            ]
                                        }
                                    ],
                                    "ix": 3
                                },
                                "r": 1
                            },
                            "a": {
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        22,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 20.0140098068648,
                "op": 61.5430801561093,
                "st": -38.5269688782148,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 3,
                "ty": 2,
                "nm": "icons8-togo-96.png",
                "cl": "png",
                "refId": "image_0",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 18.013,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 26.0182127489242,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1127.375,
                            457.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            48,
                            48,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            18,
                            18,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 4,
                "ty": 2,
                "nm": "Screenshot 2022-03-16 004724.png",
                "cl": "png",
                "parent": 7,
                "refId": "image_1",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            401.5,
                            389,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            2.174,
                            2.174,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 5,
                "ty": 4,
                "nm": "Shape Layer 28",
                "parent": 6,
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            445.625,
                            -22.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            446.625,
                            -21.25,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                363.25,
                                                -21.25
                                            ],
                                            [
                                                530,
                                                -21.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 6,
                "ty": 4,
                "nm": "Shape Layer 27",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1109,
                            444.988,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            353,
                            -47.012,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 12.008,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 20.0140098068648,
                                "s": [
                                    99.5,
                                    99.5,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "rc",
                                "d": 1,
                                "s": {
                                    "a": 0,
                                    "k": [
                                        183.5,
                                        78.024
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 5,
                                    "ix": 4
                                },
                                "nm": "Rectangle Path 1",
                                "mn": "ADBE Vector Shape - Rect",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        444.75,
                                        -8
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Rectangle 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 7,
                "ty": 4,
                "nm": "Shape Layer 226",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1090.625,
                            462.875,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 8.006,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 15.0105073551486,
                                "s": [
                                    92,
                                    92,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 25,
                        "nm": "Drop Shadow",
                        "np": 8,
                        "mn": "ADBE Drop Shadow",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 2,
                                "nm": "Shadow Color",
                                "mn": "ADBE Drop Shadow-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": [
                                        0.478431403637,
                                        0,
                                        0.858823597431,
                                        1
                                    ],
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Opacity",
                                "mn": "ADBE Drop Shadow-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 25.5,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Direction",
                                "mn": "ADBE Drop Shadow-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 135,
                                    "ix": 3
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Distance",
                                "mn": "ADBE Drop Shadow-0004",
                                "ix": 4,
                                "v": {
                                    "a": 0,
                                    "k": 1,
                                    "ix": 4
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Softness",
                                "mn": "ADBE Drop Shadow-0005",
                                "ix": 5,
                                "v": {
                                    "a": 0,
                                    "k": 59,
                                    "ix": 5
                                }
                            },
                            {
                                "ty": 7,
                                "nm": "Shadow Only",
                                "mn": "ADBE Drop Shadow-0006",
                                "ix": 6,
                                "v": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                }
                            }
                        ]
                    },
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 2,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "d": 1,
                                "ty": "el",
                                "s": {
                                    "a": 0,
                                    "k": [
                                        26.75,
                                        26.75
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "nm": "Ellipse Path 1",
                                "mn": "ADBE Vector Shape - Ellipse",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.752941236309,
                                        0.607843137255,
                                        0.796078491211,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        334.625,
                                        -30.375
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Ellipse 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 8.00560392274592,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 8,
                "ty": 4,
                "nm": "Shape Layer 25",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1044.25,
                            477,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            288.25,
                            -13.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                258.5,
                                                -0.25
                                            ],
                                            [
                                                277,
                                                -27.25
                                            ],
                                            [
                                                318,
                                                -27.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 1.5,
                                    "ix": 5
                                },
                                "lc": 2,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "d": [
                                    {
                                        "n": "d",
                                        "nm": "dash",
                                        "v": {
                                            "a": 0,
                                            "k": 2,
                                            "ix": 1
                                        }
                                    },
                                    {
                                        "n": "g",
                                        "nm": "gap",
                                        "v": {
                                            "a": 0,
                                            "k": 6,
                                            "ix": 2
                                        }
                                    },
                                    {
                                        "n": "o",
                                        "nm": "offset",
                                        "v": {
                                            "a": 1,
                                            "k": [
                                                {
                                                    "i": {
                                                        "x": [
                                                            0.833
                                                        ],
                                                        "y": [
                                                            0.833
                                                        ]
                                                    },
                                                    "o": {
                                                        "x": [
                                                            0.167
                                                        ],
                                                        "y": [
                                                            0.167
                                                        ]
                                                    },
                                                    "t": -39.027,
                                                    "s": [
                                                        990
                                                    ]
                                                },
                                                {
                                                    "t": 863.104172921045,
                                                    "s": [
                                                        146
                                                    ]
                                                }
                                            ],
                                            "ix": 7
                                        }
                                    }
                                ],
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    },
                    {
                        "ty": "tm",
                        "s": {
                            "a": 0,
                            "k": 0,
                            "ix": 1
                        },
                        "e": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.667
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.333
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 9.50665465826078,
                                    "s": [
                                        100
                                    ]
                                }
                            ],
                            "ix": 2
                        },
                        "o": {
                            "a": 0,
                            "k": 0,
                            "ix": 3
                        },
                        "m": 1,
                        "ix": 2,
                        "nm": "Trim Paths 1",
                        "mn": "ADBE Vector Filter - Trim",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            }
        ]
    },
    {
        "id": "comp_2",
        "layers": [
            {
                "ddd": 0,
                "ind": 1,
                "ty": 5,
                "nm": "MALAWI",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1139.285,
                            463.045,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 8.19999980926514,
                                    "f": "Mulish-Black",
                                    "t": "MALAWI",
                                    "j": 0,
                                    "tr": 25,
                                    "lh": 9.83999977111816,
                                    "ls": 0,
                                    "fc": [
                                        0.153,
                                        0.153,
                                        0.153
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 1,
                                "rn": 1,
                                "sh": 1,
                                "e": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 19.514,
                                            "s": [
                                                100
                                            ]
                                        },
                                        {
                                            "t": 29.5206644651256,
                                            "s": [
                                                0
                                            ]
                                        }
                                    ],
                                    "ix": 2
                                },
                                "r": 1
                            },
                            "a": {
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 2,
                "ty": 5,
                "nm": "Stay on top of regulatory changes with our constant and timely regulatory updates",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1118.75,
                            485.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "hasMask": true,
                "masksProperties": [
                    {
                        "inv": false,
                        "mode": "a",
                        "pt": {
                            "a": 0,
                            "k": {
                                "i": [
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ]
                                ],
                                "o": [
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ]
                                ],
                                "v": [
                                    [
                                        166.25,
                                        -14.5
                                    ],
                                    [
                                        9.75,
                                        -14.5
                                    ],
                                    [
                                        -10.25,
                                        5.5
                                    ],
                                    [
                                        -10.25,
                                        16.5
                                    ],
                                    [
                                        9.75,
                                        36.5
                                    ],
                                    [
                                        166.25,
                                        36.5
                                    ],
                                    [
                                        186.25,
                                        16.5
                                    ],
                                    [
                                        186.25,
                                        5.5
                                    ]
                                ],
                                "c": true
                            },
                            "ix": 1
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 3
                        },
                        "x": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                        },
                        "nm": "Mask 1"
                    }
                ],
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 10,
                                    "f": "Mulish-ExtraBold",
                                    "t": "Stay on top of regulatory changes\rwith our constant and timely\rregulatory updates",
                                    "j": 0,
                                    "tr": 10,
                                    "lh": 12,
                                    "ls": 0,
                                    "fc": [
                                        0.463,
                                        0.262,
                                        0.522
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 50,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 3,
                                "rn": 0,
                                "sh": 2,
                                "o": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 20.014,
                                            "s": [
                                                -100
                                            ]
                                        },
                                        {
                                            "t": 41.0142099469629,
                                            "s": [
                                                100
                                            ]
                                        }
                                    ],
                                    "ix": 3
                                },
                                "r": 1
                            },
                            "a": {
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        22,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 20.0140098068648,
                "op": 61.5430801561093,
                "st": -38.5269688782148,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 3,
                "ty": 2,
                "nm": "icons8-malawi-96.png",
                "cl": "png",
                "refId": "image_2",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 18.013,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 26.0182127489242,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1127.375,
                            457.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            48,
                            48,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            18,
                            18,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 4,
                "ty": 2,
                "nm": "Screenshot 2022-03-16 004724.png",
                "cl": "png",
                "parent": 7,
                "refId": "image_1",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            401.5,
                            389,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            2.174,
                            2.174,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 5,
                "ty": 4,
                "nm": "Shape Layer 28",
                "parent": 6,
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            445.625,
                            -22.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            446.625,
                            -21.25,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                363.25,
                                                -21.25
                                            ],
                                            [
                                                530,
                                                -21.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 6,
                "ty": 4,
                "nm": "Shape Layer 27",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1109,
                            444.988,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            353,
                            -47.012,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 12.008,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 20.0140098068648,
                                "s": [
                                    99.5,
                                    99.5,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "rc",
                                "d": 1,
                                "s": {
                                    "a": 0,
                                    "k": [
                                        183.5,
                                        78.024
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 5,
                                    "ix": 4
                                },
                                "nm": "Rectangle Path 1",
                                "mn": "ADBE Vector Shape - Rect",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        444.75,
                                        -8
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Rectangle 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 7,
                "ty": 4,
                "nm": "Shape Layer 226",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1090.625,
                            462.875,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 8.006,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 15.0105073551486,
                                "s": [
                                    92,
                                    92,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 25,
                        "nm": "Drop Shadow",
                        "np": 8,
                        "mn": "ADBE Drop Shadow",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 2,
                                "nm": "Shadow Color",
                                "mn": "ADBE Drop Shadow-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": [
                                        0.478431403637,
                                        0,
                                        0.858823597431,
                                        1
                                    ],
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Opacity",
                                "mn": "ADBE Drop Shadow-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 25.5,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Direction",
                                "mn": "ADBE Drop Shadow-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 135,
                                    "ix": 3
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Distance",
                                "mn": "ADBE Drop Shadow-0004",
                                "ix": 4,
                                "v": {
                                    "a": 0,
                                    "k": 1,
                                    "ix": 4
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Softness",
                                "mn": "ADBE Drop Shadow-0005",
                                "ix": 5,
                                "v": {
                                    "a": 0,
                                    "k": 59,
                                    "ix": 5
                                }
                            },
                            {
                                "ty": 7,
                                "nm": "Shadow Only",
                                "mn": "ADBE Drop Shadow-0006",
                                "ix": 6,
                                "v": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                }
                            }
                        ]
                    },
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 2,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "d": 1,
                                "ty": "el",
                                "s": {
                                    "a": 0,
                                    "k": [
                                        26.75,
                                        26.75
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "nm": "Ellipse Path 1",
                                "mn": "ADBE Vector Shape - Ellipse",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.752941236309,
                                        0.607843137255,
                                        0.796078491211,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        334.625,
                                        -30.375
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Ellipse 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 8.00560392274592,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 8,
                "ty": 4,
                "nm": "Shape Layer 25",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1044.25,
                            477,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            288.25,
                            -13.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                258.5,
                                                -0.25
                                            ],
                                            [
                                                277,
                                                -27.25
                                            ],
                                            [
                                                318,
                                                -27.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 1.5,
                                    "ix": 5
                                },
                                "lc": 2,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "d": [
                                    {
                                        "n": "d",
                                        "nm": "dash",
                                        "v": {
                                            "a": 0,
                                            "k": 2,
                                            "ix": 1
                                        }
                                    },
                                    {
                                        "n": "g",
                                        "nm": "gap",
                                        "v": {
                                            "a": 0,
                                            "k": 6,
                                            "ix": 2
                                        }
                                    },
                                    {
                                        "n": "o",
                                        "nm": "offset",
                                        "v": {
                                            "a": 1,
                                            "k": [
                                                {
                                                    "i": {
                                                        "x": [
                                                            0.833
                                                        ],
                                                        "y": [
                                                            0.833
                                                        ]
                                                    },
                                                    "o": {
                                                        "x": [
                                                            0.167
                                                        ],
                                                        "y": [
                                                            0.167
                                                        ]
                                                    },
                                                    "t": -39.027,
                                                    "s": [
                                                        990
                                                    ]
                                                },
                                                {
                                                    "t": 863.104172921045,
                                                    "s": [
                                                        146
                                                    ]
                                                }
                                            ],
                                            "ix": 7
                                        }
                                    }
                                ],
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    },
                    {
                        "ty": "tm",
                        "s": {
                            "a": 0,
                            "k": 0,
                            "ix": 1
                        },
                        "e": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.667
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.333
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 9.50665465826078,
                                    "s": [
                                        100
                                    ]
                                }
                            ],
                            "ix": 2
                        },
                        "o": {
                            "a": 0,
                            "k": 0,
                            "ix": 3
                        },
                        "m": 1,
                        "ix": 2,
                        "nm": "Trim Paths 1",
                        "mn": "ADBE Vector Filter - Trim",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            }
        ]
    },
    {
        "id": "comp_3",
        "layers": [
            {
                "ddd": 0,
                "ind": 1,
                "ty": 5,
                "nm": "SOUTH AFRICA",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1139.285,
                            463.045,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 8.19999980926514,
                                    "f": "Mulish-Black",
                                    "t": "SOUTH AFRICA",
                                    "j": 0,
                                    "tr": 25,
                                    "lh": 9.83999977111816,
                                    "ls": 0,
                                    "fc": [
                                        0.153,
                                        0.153,
                                        0.153
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 1,
                                "rn": 1,
                                "sh": 1,
                                "e": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 19.514,
                                            "s": [
                                                100
                                            ]
                                        },
                                        {
                                            "t": 29.5206644651256,
                                            "s": [
                                                0
                                            ]
                                        }
                                    ],
                                    "ix": 2
                                },
                                "r": 1
                            },
                            "a": {
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 2,
                "ty": 5,
                "nm": "Outsource compliance to cost- effective and proven experts",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1118.75,
                            485.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "hasMask": true,
                "masksProperties": [
                    {
                        "inv": false,
                        "mode": "a",
                        "pt": {
                            "a": 0,
                            "k": {
                                "i": [
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ]
                                ],
                                "o": [
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ]
                                ],
                                "v": [
                                    [
                                        166.25,
                                        -14.5
                                    ],
                                    [
                                        9.75,
                                        -14.5
                                    ],
                                    [
                                        -10.25,
                                        5.5
                                    ],
                                    [
                                        -10.25,
                                        16.5
                                    ],
                                    [
                                        9.75,
                                        36.5
                                    ],
                                    [
                                        166.25,
                                        36.5
                                    ],
                                    [
                                        186.25,
                                        16.5
                                    ],
                                    [
                                        186.25,
                                        5.5
                                    ]
                                ],
                                "c": true
                            },
                            "ix": 1
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 3
                        },
                        "x": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                        },
                        "nm": "Mask 1"
                    }
                ],
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 10,
                                    "f": "Mulish-ExtraBold",
                                    "t": "Outsource compliance to cost-\reffective and proven experts",
                                    "j": 0,
                                    "tr": 10,
                                    "lh": 12,
                                    "ls": 0,
                                    "fc": [
                                        0.463,
                                        0.262,
                                        0.522
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 50,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 3,
                                "rn": 0,
                                "sh": 2,
                                "o": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 20.014,
                                            "s": [
                                                -100
                                            ]
                                        },
                                        {
                                            "t": 41.0142099469629,
                                            "s": [
                                                100
                                            ]
                                        }
                                    ],
                                    "ix": 3
                                },
                                "r": 1
                            },
                            "a": {
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        22,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 20.0140098068648,
                "op": 61.5430801561093,
                "st": -38.5269688782148,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 3,
                "ty": 2,
                "nm": "icons8-south-africa-96.png",
                "cl": "png",
                "refId": "image_3",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 18.013,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 26.0182127489242,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1127.375,
                            457.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            48,
                            48,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            18,
                            18,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 4,
                "ty": 2,
                "nm": "Screenshot 2022-03-16 004724.png",
                "cl": "png",
                "parent": 7,
                "refId": "image_1",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            401.5,
                            389,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            2.174,
                            2.174,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 5,
                "ty": 4,
                "nm": "Shape Layer 28",
                "parent": 6,
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            445.625,
                            -22.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            446.625,
                            -21.25,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                363.25,
                                                -21.25
                                            ],
                                            [
                                                530,
                                                -21.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 6,
                "ty": 4,
                "nm": "Shape Layer 27",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1109,
                            444.988,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            353,
                            -47.012,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 12.008,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 20.0140098068648,
                                "s": [
                                    99.5,
                                    99.5,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "rc",
                                "d": 1,
                                "s": {
                                    "a": 0,
                                    "k": [
                                        183.5,
                                        78.024
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 5,
                                    "ix": 4
                                },
                                "nm": "Rectangle Path 1",
                                "mn": "ADBE Vector Shape - Rect",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        444.75,
                                        -8
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Rectangle 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 7,
                "ty": 4,
                "nm": "Shape Layer 226",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1090.625,
                            462.875,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 8.006,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 15.0105073551486,
                                "s": [
                                    92,
                                    92,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 25,
                        "nm": "Drop Shadow",
                        "np": 8,
                        "mn": "ADBE Drop Shadow",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 2,
                                "nm": "Shadow Color",
                                "mn": "ADBE Drop Shadow-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": [
                                        0.478431403637,
                                        0,
                                        0.858823597431,
                                        1
                                    ],
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Opacity",
                                "mn": "ADBE Drop Shadow-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 25.5,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Direction",
                                "mn": "ADBE Drop Shadow-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 135,
                                    "ix": 3
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Distance",
                                "mn": "ADBE Drop Shadow-0004",
                                "ix": 4,
                                "v": {
                                    "a": 0,
                                    "k": 1,
                                    "ix": 4
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Softness",
                                "mn": "ADBE Drop Shadow-0005",
                                "ix": 5,
                                "v": {
                                    "a": 0,
                                    "k": 59,
                                    "ix": 5
                                }
                            },
                            {
                                "ty": 7,
                                "nm": "Shadow Only",
                                "mn": "ADBE Drop Shadow-0006",
                                "ix": 6,
                                "v": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                }
                            }
                        ]
                    },
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 2,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "d": 1,
                                "ty": "el",
                                "s": {
                                    "a": 0,
                                    "k": [
                                        26.75,
                                        26.75
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "nm": "Ellipse Path 1",
                                "mn": "ADBE Vector Shape - Ellipse",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.752941236309,
                                        0.607843137255,
                                        0.796078491211,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        334.625,
                                        -30.375
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Ellipse 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 8.00560392274592,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 8,
                "ty": 4,
                "nm": "Shape Layer 25",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1044.25,
                            477,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            288.25,
                            -13.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                258.5,
                                                -0.25
                                            ],
                                            [
                                                277,
                                                -27.25
                                            ],
                                            [
                                                318,
                                                -27.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 1.5,
                                    "ix": 5
                                },
                                "lc": 2,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "d": [
                                    {
                                        "n": "d",
                                        "nm": "dash",
                                        "v": {
                                            "a": 0,
                                            "k": 2,
                                            "ix": 1
                                        }
                                    },
                                    {
                                        "n": "g",
                                        "nm": "gap",
                                        "v": {
                                            "a": 0,
                                            "k": 6,
                                            "ix": 2
                                        }
                                    },
                                    {
                                        "n": "o",
                                        "nm": "offset",
                                        "v": {
                                            "a": 1,
                                            "k": [
                                                {
                                                    "i": {
                                                        "x": [
                                                            0.833
                                                        ],
                                                        "y": [
                                                            0.833
                                                        ]
                                                    },
                                                    "o": {
                                                        "x": [
                                                            0.167
                                                        ],
                                                        "y": [
                                                            0.167
                                                        ]
                                                    },
                                                    "t": -39.027,
                                                    "s": [
                                                        990
                                                    ]
                                                },
                                                {
                                                    "t": 863.104172921045,
                                                    "s": [
                                                        146
                                                    ]
                                                }
                                            ],
                                            "ix": 7
                                        }
                                    }
                                ],
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    },
                    {
                        "ty": "tm",
                        "s": {
                            "a": 0,
                            "k": 0,
                            "ix": 1
                        },
                        "e": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.667
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.333
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 9.50665465826078,
                                    "s": [
                                        100
                                    ]
                                }
                            ],
                            "ix": 2
                        },
                        "o": {
                            "a": 0,
                            "k": 0,
                            "ix": 3
                        },
                        "m": 1,
                        "ix": 2,
                        "nm": "Trim Paths 1",
                        "mn": "ADBE Vector Filter - Trim",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            }
        ]
    },
    {
        "id": "comp_4",
        "layers": [
            {
                "ddd": 0,
                "ind": 1,
                "ty": 5,
                "nm": "Madagasca",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            818.285,
                            489.045,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 8.19999980926514,
                                    "f": "Mulish-Black",
                                    "t": "Madagasca",
                                    "j": 0,
                                    "tr": 25,
                                    "lh": 9.83999977111816,
                                    "ls": 0,
                                    "fc": [
                                        0.153,
                                        0.153,
                                        0.153
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 1,
                                "rn": 1,
                                "sh": 1,
                                "e": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 19.514,
                                            "s": [
                                                100
                                            ]
                                        },
                                        {
                                            "t": 29.5206644651256,
                                            "s": [
                                                0
                                            ]
                                        }
                                    ],
                                    "ix": 2
                                },
                                "r": 1
                            },
                            "a": {
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 2,
                "ty": 5,
                "nm": "Understand and plug gaps in your company’s compliance through our GAP analysts and auditors",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            797.75,
                            511.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "hasMask": true,
                "masksProperties": [
                    {
                        "inv": false,
                        "mode": "a",
                        "pt": {
                            "a": 0,
                            "k": {
                                "i": [
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ]
                                ],
                                "o": [
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ]
                                ],
                                "v": [
                                    [
                                        166.25,
                                        -14.5
                                    ],
                                    [
                                        9.75,
                                        -14.5
                                    ],
                                    [
                                        -10.25,
                                        5.5
                                    ],
                                    [
                                        -10.25,
                                        16.5
                                    ],
                                    [
                                        9.75,
                                        36.5
                                    ],
                                    [
                                        166.25,
                                        36.5
                                    ],
                                    [
                                        186.25,
                                        16.5
                                    ],
                                    [
                                        186.25,
                                        5.5
                                    ]
                                ],
                                "c": true
                            },
                            "ix": 1
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 3
                        },
                        "x": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                        },
                        "nm": "Mask 1"
                    }
                ],
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 10,
                                    "f": "Mulish-ExtraBold",
                                    "t": "Understand and plug gaps in your\rcompany’s compliance through\rour GAP analysts and auditors",
                                    "j": 0,
                                    "tr": 10,
                                    "lh": 12,
                                    "ls": 0,
                                    "fc": [
                                        0.463,
                                        0.263,
                                        0.522
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 50,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 3,
                                "rn": 0,
                                "sh": 2,
                                "o": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 20.014,
                                            "s": [
                                                -100
                                            ]
                                        },
                                        {
                                            "t": 41.0142099469629,
                                            "s": [
                                                100
                                            ]
                                        }
                                    ],
                                    "ix": 3
                                },
                                "r": 1
                            },
                            "a": {
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        22,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 20.0140098068648,
                "op": 61.5430801561093,
                "st": -38.5269688782148,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 3,
                "ty": 2,
                "nm": "icons8-madagascar-96.png",
                "cl": "png",
                "refId": "image_4",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 18.013,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 26.0182127489242,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            806.375,
                            483.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            48,
                            48,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            18,
                            18,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 4,
                "ty": 2,
                "nm": "Screenshot 2022-03-16 004724.png",
                "cl": "png",
                "parent": 7,
                "refId": "image_1",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            401.5,
                            389,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            2.174,
                            2.174,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 5,
                "ty": 4,
                "nm": "Shape Layer 28",
                "parent": 6,
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            445.625,
                            -22.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            446.625,
                            -21.25,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                363.25,
                                                -21.25
                                            ],
                                            [
                                                530,
                                                -21.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 6,
                "ty": 4,
                "nm": "Shape Layer 27",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            788,
                            470.988,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            353,
                            -47.012,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 12.008,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 20.0140098068648,
                                "s": [
                                    99.5,
                                    99.5,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "rc",
                                "d": 1,
                                "s": {
                                    "a": 0,
                                    "k": [
                                        183.5,
                                        78.024
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 5,
                                    "ix": 4
                                },
                                "nm": "Rectangle Path 1",
                                "mn": "ADBE Vector Shape - Rect",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        444.75,
                                        -8
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Rectangle 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 7,
                "ty": 4,
                "nm": "Shape Layer 226",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            994.625,
                            485.875,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 8.006,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 15.0105073551486,
                                "s": [
                                    92,
                                    92,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 25,
                        "nm": "Drop Shadow",
                        "np": 8,
                        "mn": "ADBE Drop Shadow",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 2,
                                "nm": "Shadow Color",
                                "mn": "ADBE Drop Shadow-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": [
                                        0.478431403637,
                                        0,
                                        0.858823597431,
                                        1
                                    ],
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Opacity",
                                "mn": "ADBE Drop Shadow-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 25.5,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Direction",
                                "mn": "ADBE Drop Shadow-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 135,
                                    "ix": 3
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Distance",
                                "mn": "ADBE Drop Shadow-0004",
                                "ix": 4,
                                "v": {
                                    "a": 0,
                                    "k": 1,
                                    "ix": 4
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Softness",
                                "mn": "ADBE Drop Shadow-0005",
                                "ix": 5,
                                "v": {
                                    "a": 0,
                                    "k": 59,
                                    "ix": 5
                                }
                            },
                            {
                                "ty": 7,
                                "nm": "Shadow Only",
                                "mn": "ADBE Drop Shadow-0006",
                                "ix": 6,
                                "v": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                }
                            }
                        ]
                    },
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 2,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "d": 1,
                                "ty": "el",
                                "s": {
                                    "a": 0,
                                    "k": [
                                        26.75,
                                        26.75
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "nm": "Ellipse Path 1",
                                "mn": "ADBE Vector Shape - Ellipse",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.752941236309,
                                        0.607843137255,
                                        0.796078491211,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        334.625,
                                        -30.375
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Ellipse 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 8.00560392274592,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 8,
                "ty": 4,
                "nm": "Shape Layer 25",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 180,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1044.25,
                            477,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            288.25,
                            -13.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                258.5,
                                                -0.25
                                            ],
                                            [
                                                277,
                                                -27.25
                                            ],
                                            [
                                                318,
                                                -27.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 1.5,
                                    "ix": 5
                                },
                                "lc": 2,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "d": [
                                    {
                                        "n": "d",
                                        "nm": "dash",
                                        "v": {
                                            "a": 0,
                                            "k": 2,
                                            "ix": 1
                                        }
                                    },
                                    {
                                        "n": "g",
                                        "nm": "gap",
                                        "v": {
                                            "a": 0,
                                            "k": 6,
                                            "ix": 2
                                        }
                                    },
                                    {
                                        "n": "o",
                                        "nm": "offset",
                                        "v": {
                                            "a": 1,
                                            "k": [
                                                {
                                                    "i": {
                                                        "x": [
                                                            0.833
                                                        ],
                                                        "y": [
                                                            0.833
                                                        ]
                                                    },
                                                    "o": {
                                                        "x": [
                                                            0.167
                                                        ],
                                                        "y": [
                                                            0.167
                                                        ]
                                                    },
                                                    "t": -39.027,
                                                    "s": [
                                                        990
                                                    ]
                                                },
                                                {
                                                    "t": 863.104172921045,
                                                    "s": [
                                                        146
                                                    ]
                                                }
                                            ],
                                            "ix": 7
                                        }
                                    }
                                ],
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    },
                    {
                        "ty": "tm",
                        "s": {
                            "a": 0,
                            "k": 0,
                            "ix": 1
                        },
                        "e": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.667
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.333
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 9.50665465826078,
                                    "s": [
                                        100
                                    ]
                                }
                            ],
                            "ix": 2
                        },
                        "o": {
                            "a": 0,
                            "k": 0,
                            "ix": 3
                        },
                        "m": 1,
                        "ix": 2,
                        "nm": "Trim Paths 1",
                        "mn": "ADBE Vector Filter - Trim",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            }
        ]
    },
    {
        "id": "comp_5",
        "layers": [
            {
                "ddd": 0,
                "ind": 1,
                "ty": 5,
                "nm": "ETHIOPIA",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1139.285,
                            463.045,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 8.19999980926514,
                                    "f": "Mulish-Black",
                                    "t": "ETHIOPIA",
                                    "j": 0,
                                    "tr": 25,
                                    "lh": 9.83999977111816,
                                    "ls": 0,
                                    "fc": [
                                        0.153,
                                        0.153,
                                        0.153
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 1,
                                "rn": 1,
                                "sh": 1,
                                "e": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 19.514,
                                            "s": [
                                                100
                                            ]
                                        },
                                        {
                                            "t": 29.5206644651256,
                                            "s": [
                                                0
                                            ]
                                        }
                                    ],
                                    "ix": 2
                                },
                                "r": 1
                            },
                            "a": {
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 2,
                "ty": 5,
                "nm": "Ensure your customer’s privacy with our privacy regulations compliance tools",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1118.75,
                            485.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "hasMask": true,
                "masksProperties": [
                    {
                        "inv": false,
                        "mode": "a",
                        "pt": {
                            "a": 0,
                            "k": {
                                "i": [
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ]
                                ],
                                "o": [
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ]
                                ],
                                "v": [
                                    [
                                        166.25,
                                        -14.5
                                    ],
                                    [
                                        9.75,
                                        -14.5
                                    ],
                                    [
                                        -10.25,
                                        5.5
                                    ],
                                    [
                                        -10.25,
                                        16.5
                                    ],
                                    [
                                        9.75,
                                        36.5
                                    ],
                                    [
                                        166.25,
                                        36.5
                                    ],
                                    [
                                        186.25,
                                        16.5
                                    ],
                                    [
                                        186.25,
                                        5.5
                                    ]
                                ],
                                "c": true
                            },
                            "ix": 1
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 3
                        },
                        "x": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                        },
                        "nm": "Mask 1"
                    }
                ],
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 10,
                                    "f": "Mulish-ExtraBold",
                                    "t": "Ensure your customer’s privacy\rwith our privacy regulations\rcompliance tools",
                                    "j": 0,
                                    "tr": 10,
                                    "lh": 12,
                                    "ls": 0,
                                    "fc": [
                                        0.463,
                                        0.263,
                                        0.522
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 50,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 3,
                                "rn": 0,
                                "sh": 2,
                                "o": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 20.014,
                                            "s": [
                                                -100
                                            ]
                                        },
                                        {
                                            "t": 41.0142099469629,
                                            "s": [
                                                100
                                            ]
                                        }
                                    ],
                                    "ix": 3
                                },
                                "r": 1
                            },
                            "a": {
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        22,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 20.0140098068648,
                "op": 61.5430801561093,
                "st": -38.5269688782148,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 3,
                "ty": 2,
                "nm": "icons8-ethiopia-96.png",
                "cl": "png",
                "refId": "image_5",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 18.013,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 26.0182127489242,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1127.375,
                            457.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            48,
                            48,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            18,
                            18,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 4,
                "ty": 2,
                "nm": "Screenshot 2022-03-16 004724.png",
                "cl": "png",
                "parent": 7,
                "refId": "image_1",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            401.5,
                            389,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            2.174,
                            2.174,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 5,
                "ty": 4,
                "nm": "Shape Layer 28",
                "parent": 6,
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            445.625,
                            -22.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            446.625,
                            -21.25,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                363.25,
                                                -21.25
                                            ],
                                            [
                                                530,
                                                -21.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 6,
                "ty": 4,
                "nm": "Shape Layer 27",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1109,
                            444.988,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            353,
                            -47.012,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 12.008,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 20.0140098068648,
                                "s": [
                                    99.5,
                                    99.5,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "rc",
                                "d": 1,
                                "s": {
                                    "a": 0,
                                    "k": [
                                        183.5,
                                        78.024
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 5,
                                    "ix": 4
                                },
                                "nm": "Rectangle Path 1",
                                "mn": "ADBE Vector Shape - Rect",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        444.75,
                                        -8
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Rectangle 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 7,
                "ty": 4,
                "nm": "Shape Layer 226",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1090.625,
                            462.875,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 8.006,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 15.0105073551486,
                                "s": [
                                    92,
                                    92,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 25,
                        "nm": "Drop Shadow",
                        "np": 8,
                        "mn": "ADBE Drop Shadow",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 2,
                                "nm": "Shadow Color",
                                "mn": "ADBE Drop Shadow-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": [
                                        0.478431403637,
                                        0,
                                        0.858823597431,
                                        1
                                    ],
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Opacity",
                                "mn": "ADBE Drop Shadow-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 25.5,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Direction",
                                "mn": "ADBE Drop Shadow-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 135,
                                    "ix": 3
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Distance",
                                "mn": "ADBE Drop Shadow-0004",
                                "ix": 4,
                                "v": {
                                    "a": 0,
                                    "k": 1,
                                    "ix": 4
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Softness",
                                "mn": "ADBE Drop Shadow-0005",
                                "ix": 5,
                                "v": {
                                    "a": 0,
                                    "k": 59,
                                    "ix": 5
                                }
                            },
                            {
                                "ty": 7,
                                "nm": "Shadow Only",
                                "mn": "ADBE Drop Shadow-0006",
                                "ix": 6,
                                "v": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                }
                            }
                        ]
                    },
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 2,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "d": 1,
                                "ty": "el",
                                "s": {
                                    "a": 0,
                                    "k": [
                                        26.75,
                                        26.75
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "nm": "Ellipse Path 1",
                                "mn": "ADBE Vector Shape - Ellipse",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.752941236309,
                                        0.607843137255,
                                        0.796078491211,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        334.625,
                                        -30.375
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Ellipse 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 8.00560392274592,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 8,
                "ty": 4,
                "nm": "Shape Layer 25",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1044.25,
                            477,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            288.25,
                            -13.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                258.5,
                                                -0.25
                                            ],
                                            [
                                                277,
                                                -27.25
                                            ],
                                            [
                                                318,
                                                -27.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 1.5,
                                    "ix": 5
                                },
                                "lc": 2,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "d": [
                                    {
                                        "n": "d",
                                        "nm": "dash",
                                        "v": {
                                            "a": 0,
                                            "k": 2,
                                            "ix": 1
                                        }
                                    },
                                    {
                                        "n": "g",
                                        "nm": "gap",
                                        "v": {
                                            "a": 0,
                                            "k": 6,
                                            "ix": 2
                                        }
                                    },
                                    {
                                        "n": "o",
                                        "nm": "offset",
                                        "v": {
                                            "a": 1,
                                            "k": [
                                                {
                                                    "i": {
                                                        "x": [
                                                            0.833
                                                        ],
                                                        "y": [
                                                            0.833
                                                        ]
                                                    },
                                                    "o": {
                                                        "x": [
                                                            0.167
                                                        ],
                                                        "y": [
                                                            0.167
                                                        ]
                                                    },
                                                    "t": -39.027,
                                                    "s": [
                                                        990
                                                    ]
                                                },
                                                {
                                                    "t": 863.104172921045,
                                                    "s": [
                                                        146
                                                    ]
                                                }
                                            ],
                                            "ix": 7
                                        }
                                    }
                                ],
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    },
                    {
                        "ty": "tm",
                        "s": {
                            "a": 0,
                            "k": 0,
                            "ix": 1
                        },
                        "e": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.667
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.333
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 9.50665465826078,
                                    "s": [
                                        100
                                    ]
                                }
                            ],
                            "ix": 2
                        },
                        "o": {
                            "a": 0,
                            "k": 0,
                            "ix": 3
                        },
                        "m": 1,
                        "ix": 2,
                        "nm": "Trim Paths 1",
                        "mn": "ADBE Vector Filter - Trim",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            }
        ]
    },
    {
        "id": "comp_6",
        "layers": [
            {
                "ddd": 0,
                "ind": 1,
                "ty": 5,
                "nm": "NIGER",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1139.285,
                            463.045,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 8.19999980926514,
                                    "f": "Mulish-Black",
                                    "t": "NIGER",
                                    "j": 0,
                                    "tr": 25,
                                    "lh": 9.83999977111816,
                                    "ls": 0,
                                    "fc": [
                                        0.153,
                                        0.153,
                                        0.153
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 1,
                                "rn": 1,
                                "sh": 1,
                                "e": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 19.514,
                                            "s": [
                                                100
                                            ]
                                        },
                                        {
                                            "t": 29.5206644651256,
                                            "s": [
                                                0
                                            ]
                                        }
                                    ],
                                    "ix": 2
                                },
                                "r": 1
                            },
                            "a": {
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 2,
                "ty": 5,
                "nm": "Navigate the complexities of compliance with the aid of our compliance solutions",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1118.75,
                            485.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "hasMask": true,
                "masksProperties": [
                    {
                        "inv": false,
                        "mode": "a",
                        "pt": {
                            "a": 0,
                            "k": {
                                "i": [
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ]
                                ],
                                "o": [
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ]
                                ],
                                "v": [
                                    [
                                        166.25,
                                        -14.5
                                    ],
                                    [
                                        9.75,
                                        -14.5
                                    ],
                                    [
                                        -10.25,
                                        5.5
                                    ],
                                    [
                                        -10.25,
                                        16.5
                                    ],
                                    [
                                        9.75,
                                        36.5
                                    ],
                                    [
                                        166.25,
                                        36.5
                                    ],
                                    [
                                        186.25,
                                        16.5
                                    ],
                                    [
                                        186.25,
                                        5.5
                                    ]
                                ],
                                "c": true
                            },
                            "ix": 1
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 3
                        },
                        "x": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                        },
                        "nm": "Mask 1"
                    }
                ],
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 10,
                                    "f": "Mulish-ExtraBold",
                                    "t": "Navigate the complexities of\rcompliance with the aid of our\rcompliance solutions",
                                    "j": 0,
                                    "tr": 10,
                                    "lh": 12,
                                    "ls": 0,
                                    "fc": [
                                        0.463,
                                        0.262,
                                        0.522
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 50,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 3,
                                "rn": 0,
                                "sh": 2,
                                "o": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 20.014,
                                            "s": [
                                                -100
                                            ]
                                        },
                                        {
                                            "t": 41.0142099469629,
                                            "s": [
                                                100
                                            ]
                                        }
                                    ],
                                    "ix": 3
                                },
                                "r": 1
                            },
                            "a": {
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        22,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 20.0140098068648,
                "op": 61.5430801561093,
                "st": -38.5269688782148,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 3,
                "ty": 2,
                "nm": "icons8-niger-96.png",
                "cl": "png",
                "refId": "image_6",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 18.013,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 26.0182127489242,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1127.375,
                            457.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            48,
                            48,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            18,
                            18,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 4,
                "ty": 2,
                "nm": "Screenshot 2022-03-16 004724.png",
                "cl": "png",
                "parent": 7,
                "refId": "image_1",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            401.5,
                            389,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            2.174,
                            2.174,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 5,
                "ty": 4,
                "nm": "Shape Layer 28",
                "parent": 6,
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            445.625,
                            -22.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            446.625,
                            -21.25,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                363.25,
                                                -21.25
                                            ],
                                            [
                                                530,
                                                -21.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 6,
                "ty": 4,
                "nm": "Shape Layer 27",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1109,
                            444.988,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            353,
                            -47.012,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 12.008,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 20.0140098068648,
                                "s": [
                                    99.5,
                                    99.5,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "rc",
                                "d": 1,
                                "s": {
                                    "a": 0,
                                    "k": [
                                        183.5,
                                        78.024
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 5,
                                    "ix": 4
                                },
                                "nm": "Rectangle Path 1",
                                "mn": "ADBE Vector Shape - Rect",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        444.75,
                                        -8
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Rectangle 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 7,
                "ty": 4,
                "nm": "Shape Layer 226",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1090.625,
                            462.875,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 8.006,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 15.0105073551486,
                                "s": [
                                    92,
                                    92,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 25,
                        "nm": "Drop Shadow",
                        "np": 8,
                        "mn": "ADBE Drop Shadow",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 2,
                                "nm": "Shadow Color",
                                "mn": "ADBE Drop Shadow-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": [
                                        0.478431403637,
                                        0,
                                        0.858823597431,
                                        1
                                    ],
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Opacity",
                                "mn": "ADBE Drop Shadow-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 25.5,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Direction",
                                "mn": "ADBE Drop Shadow-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 135,
                                    "ix": 3
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Distance",
                                "mn": "ADBE Drop Shadow-0004",
                                "ix": 4,
                                "v": {
                                    "a": 0,
                                    "k": 1,
                                    "ix": 4
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Softness",
                                "mn": "ADBE Drop Shadow-0005",
                                "ix": 5,
                                "v": {
                                    "a": 0,
                                    "k": 59,
                                    "ix": 5
                                }
                            },
                            {
                                "ty": 7,
                                "nm": "Shadow Only",
                                "mn": "ADBE Drop Shadow-0006",
                                "ix": 6,
                                "v": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                }
                            }
                        ]
                    },
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 2,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "d": 1,
                                "ty": "el",
                                "s": {
                                    "a": 0,
                                    "k": [
                                        26.75,
                                        26.75
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "nm": "Ellipse Path 1",
                                "mn": "ADBE Vector Shape - Ellipse",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.752941236309,
                                        0.607843137255,
                                        0.796078491211,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        334.625,
                                        -30.375
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Ellipse 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 8.00560392274592,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 8,
                "ty": 4,
                "nm": "Shape Layer 25",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1044.25,
                            477,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            288.25,
                            -13.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                258.5,
                                                -0.25
                                            ],
                                            [
                                                277,
                                                -27.25
                                            ],
                                            [
                                                318,
                                                -27.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 1.5,
                                    "ix": 5
                                },
                                "lc": 2,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "d": [
                                    {
                                        "n": "d",
                                        "nm": "dash",
                                        "v": {
                                            "a": 0,
                                            "k": 2,
                                            "ix": 1
                                        }
                                    },
                                    {
                                        "n": "g",
                                        "nm": "gap",
                                        "v": {
                                            "a": 0,
                                            "k": 6,
                                            "ix": 2
                                        }
                                    },
                                    {
                                        "n": "o",
                                        "nm": "offset",
                                        "v": {
                                            "a": 1,
                                            "k": [
                                                {
                                                    "i": {
                                                        "x": [
                                                            0.833
                                                        ],
                                                        "y": [
                                                            0.833
                                                        ]
                                                    },
                                                    "o": {
                                                        "x": [
                                                            0.167
                                                        ],
                                                        "y": [
                                                            0.167
                                                        ]
                                                    },
                                                    "t": -39.027,
                                                    "s": [
                                                        990
                                                    ]
                                                },
                                                {
                                                    "t": 863.104172921045,
                                                    "s": [
                                                        146
                                                    ]
                                                }
                                            ],
                                            "ix": 7
                                        }
                                    }
                                ],
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    },
                    {
                        "ty": "tm",
                        "s": {
                            "a": 0,
                            "k": 0,
                            "ix": 1
                        },
                        "e": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.667
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.333
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 9.50665465826078,
                                    "s": [
                                        100
                                    ]
                                }
                            ],
                            "ix": 2
                        },
                        "o": {
                            "a": 0,
                            "k": 0,
                            "ix": 3
                        },
                        "m": 1,
                        "ix": 2,
                        "nm": "Trim Paths 1",
                        "mn": "ADBE Vector Filter - Trim",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            }
        ]
    },
    {
        "id": "comp_7",
        "layers": [
            {
                "ddd": 0,
                "ind": 1,
                "ty": 5,
                "nm": "GHANA",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1139.285,
                            463.045,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 8.19999980926514,
                                    "f": "Mulish-Black",
                                    "t": "GHANA",
                                    "j": 0,
                                    "tr": 25,
                                    "lh": 9.83999977111816,
                                    "ls": 0,
                                    "fc": [
                                        0.153,
                                        0.153,
                                        0.153
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 1,
                                "rn": 1,
                                "sh": 1,
                                "e": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 19.514,
                                            "s": [
                                                100
                                            ]
                                        },
                                        {
                                            "t": 29.5206644651256,
                                            "s": [
                                                0
                                            ]
                                        }
                                    ],
                                    "ix": 2
                                },
                                "r": 1
                            },
                            "a": {
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 2,
                "ty": 5,
                "nm": "Expand to other African countries with the help of our experts",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1118.75,
                            485.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "hasMask": true,
                "masksProperties": [
                    {
                        "inv": false,
                        "mode": "a",
                        "pt": {
                            "a": 0,
                            "k": {
                                "i": [
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ]
                                ],
                                "o": [
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ]
                                ],
                                "v": [
                                    [
                                        166.25,
                                        -14.5
                                    ],
                                    [
                                        9.75,
                                        -14.5
                                    ],
                                    [
                                        -10.25,
                                        5.5
                                    ],
                                    [
                                        -10.25,
                                        16.5
                                    ],
                                    [
                                        9.75,
                                        36.5
                                    ],
                                    [
                                        166.25,
                                        36.5
                                    ],
                                    [
                                        186.25,
                                        16.5
                                    ],
                                    [
                                        186.25,
                                        5.5
                                    ]
                                ],
                                "c": true
                            },
                            "ix": 1
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 3
                        },
                        "x": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                        },
                        "nm": "Mask 1"
                    }
                ],
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 10,
                                    "f": "Mulish-ExtraBold",
                                    "t": "Expand to other African\rcountries with the help of our\rexperts",
                                    "j": 0,
                                    "tr": 10,
                                    "lh": 12,
                                    "ls": 0,
                                    "fc": [
                                        0.463,
                                        0.262,
                                        0.522
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 50,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 3,
                                "rn": 0,
                                "sh": 2,
                                "o": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 20.014,
                                            "s": [
                                                -100
                                            ]
                                        },
                                        {
                                            "t": 41.0142099469629,
                                            "s": [
                                                100
                                            ]
                                        }
                                    ],
                                    "ix": 3
                                },
                                "r": 1
                            },
                            "a": {
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        22,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 20.0140098068648,
                "op": 61.5430801561093,
                "st": -38.5269688782148,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 3,
                "ty": 2,
                "nm": "icons8-ghana-96.png",
                "cl": "png",
                "refId": "image_7",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 18.013,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 26.0182127489242,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1127.375,
                            457.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            48,
                            48,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            18,
                            18,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 4,
                "ty": 2,
                "nm": "Screenshot 2022-03-16 004724.png",
                "cl": "png",
                "parent": 7,
                "refId": "image_1",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            401.5,
                            389,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            2.174,
                            2.174,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 5,
                "ty": 4,
                "nm": "Shape Layer 28",
                "parent": 6,
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            445.625,
                            -22.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            446.625,
                            -21.25,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                363.25,
                                                -21.25
                                            ],
                                            [
                                                530,
                                                -21.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 6,
                "ty": 4,
                "nm": "Shape Layer 27",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1109,
                            444.988,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            353,
                            -47.012,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 12.008,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 20.0140098068648,
                                "s": [
                                    99.5,
                                    99.5,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "rc",
                                "d": 1,
                                "s": {
                                    "a": 0,
                                    "k": [
                                        183.5,
                                        78.024
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 5,
                                    "ix": 4
                                },
                                "nm": "Rectangle Path 1",
                                "mn": "ADBE Vector Shape - Rect",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        444.75,
                                        -8
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Rectangle 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 7,
                "ty": 4,
                "nm": "Shape Layer 226",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1090.625,
                            462.875,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 8.006,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 15.0105073551486,
                                "s": [
                                    92,
                                    92,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 25,
                        "nm": "Drop Shadow",
                        "np": 8,
                        "mn": "ADBE Drop Shadow",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 2,
                                "nm": "Shadow Color",
                                "mn": "ADBE Drop Shadow-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": [
                                        0.478431403637,
                                        0,
                                        0.858823597431,
                                        1
                                    ],
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Opacity",
                                "mn": "ADBE Drop Shadow-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 25.5,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Direction",
                                "mn": "ADBE Drop Shadow-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 135,
                                    "ix": 3
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Distance",
                                "mn": "ADBE Drop Shadow-0004",
                                "ix": 4,
                                "v": {
                                    "a": 0,
                                    "k": 1,
                                    "ix": 4
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Softness",
                                "mn": "ADBE Drop Shadow-0005",
                                "ix": 5,
                                "v": {
                                    "a": 0,
                                    "k": 59,
                                    "ix": 5
                                }
                            },
                            {
                                "ty": 7,
                                "nm": "Shadow Only",
                                "mn": "ADBE Drop Shadow-0006",
                                "ix": 6,
                                "v": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                }
                            }
                        ]
                    },
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 2,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "d": 1,
                                "ty": "el",
                                "s": {
                                    "a": 0,
                                    "k": [
                                        26.75,
                                        26.75
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "nm": "Ellipse Path 1",
                                "mn": "ADBE Vector Shape - Ellipse",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.752941236309,
                                        0.607843137255,
                                        0.796078491211,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        334.625,
                                        -30.375
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Ellipse 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 8.00560392274592,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 8,
                "ty": 4,
                "nm": "Shape Layer 25",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1044.25,
                            477,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            288.25,
                            -13.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                258.5,
                                                -0.25
                                            ],
                                            [
                                                277,
                                                -27.25
                                            ],
                                            [
                                                318,
                                                -27.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 1.5,
                                    "ix": 5
                                },
                                "lc": 2,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "d": [
                                    {
                                        "n": "d",
                                        "nm": "dash",
                                        "v": {
                                            "a": 0,
                                            "k": 2,
                                            "ix": 1
                                        }
                                    },
                                    {
                                        "n": "g",
                                        "nm": "gap",
                                        "v": {
                                            "a": 0,
                                            "k": 6,
                                            "ix": 2
                                        }
                                    },
                                    {
                                        "n": "o",
                                        "nm": "offset",
                                        "v": {
                                            "a": 1,
                                            "k": [
                                                {
                                                    "i": {
                                                        "x": [
                                                            0.833
                                                        ],
                                                        "y": [
                                                            0.833
                                                        ]
                                                    },
                                                    "o": {
                                                        "x": [
                                                            0.167
                                                        ],
                                                        "y": [
                                                            0.167
                                                        ]
                                                    },
                                                    "t": -39.027,
                                                    "s": [
                                                        990
                                                    ]
                                                },
                                                {
                                                    "t": 863.104172921045,
                                                    "s": [
                                                        146
                                                    ]
                                                }
                                            ],
                                            "ix": 7
                                        }
                                    }
                                ],
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    },
                    {
                        "ty": "tm",
                        "s": {
                            "a": 0,
                            "k": 0,
                            "ix": 1
                        },
                        "e": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.667
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.333
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 9.50665465826078,
                                    "s": [
                                        100
                                    ]
                                }
                            ],
                            "ix": 2
                        },
                        "o": {
                            "a": 0,
                            "k": 0,
                            "ix": 3
                        },
                        "m": 1,
                        "ix": 2,
                        "nm": "Trim Paths 1",
                        "mn": "ADBE Vector Filter - Trim",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            }
        ]
    },
    {
        "id": "comp_8",
        "layers": [
            {
                "ddd": 0,
                "ind": 1,
                "ty": 5,
                "nm": "KENYA",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1139.285,
                            463.045,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 8.19999980926514,
                                    "f": "Mulish-Black",
                                    "t": "KENYA",
                                    "j": 0,
                                    "tr": 25,
                                    "lh": 9.83999977111816,
                                    "ls": 0,
                                    "fc": [
                                        0.153,
                                        0.153,
                                        0.153
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 1,
                                "rn": 1,
                                "sh": 1,
                                "e": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 19.514,
                                            "s": [
                                                100
                                            ]
                                        },
                                        {
                                            "t": 29.5206644651256,
                                            "s": [
                                                0
                                            ]
                                        }
                                    ],
                                    "ix": 2
                                },
                                "r": 1
                            },
                            "a": {
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 2,
                "ty": 5,
                "nm": "Set up shop and sell to Africans while we handle your compliance hassles",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1118.75,
                            485.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "hasMask": true,
                "masksProperties": [
                    {
                        "inv": false,
                        "mode": "a",
                        "pt": {
                            "a": 0,
                            "k": {
                                "i": [
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ]
                                ],
                                "o": [
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ]
                                ],
                                "v": [
                                    [
                                        166.25,
                                        -14.5
                                    ],
                                    [
                                        9.75,
                                        -14.5
                                    ],
                                    [
                                        -10.25,
                                        5.5
                                    ],
                                    [
                                        -10.25,
                                        16.5
                                    ],
                                    [
                                        9.75,
                                        36.5
                                    ],
                                    [
                                        166.25,
                                        36.5
                                    ],
                                    [
                                        186.25,
                                        16.5
                                    ],
                                    [
                                        186.25,
                                        5.5
                                    ]
                                ],
                                "c": true
                            },
                            "ix": 1
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 3
                        },
                        "x": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                        },
                        "nm": "Mask 1"
                    }
                ],
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 10,
                                    "f": "Mulish-ExtraBold",
                                    "t": "Set up shop and sell to Africans\rwhile we handle your compliance\rhassles",
                                    "j": 0,
                                    "tr": 10,
                                    "lh": 12,
                                    "ls": 0,
                                    "fc": [
                                        0.463,
                                        0.262,
                                        0.522
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 50,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 3,
                                "rn": 0,
                                "sh": 2,
                                "o": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 20.014,
                                            "s": [
                                                -100
                                            ]
                                        },
                                        {
                                            "t": 41.0142099469629,
                                            "s": [
                                                100
                                            ]
                                        }
                                    ],
                                    "ix": 3
                                },
                                "r": 1
                            },
                            "a": {
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        22,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 20.0140098068648,
                "op": 61.5430801561093,
                "st": -38.5269688782148,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 3,
                "ty": 2,
                "nm": "icons8-kenya-96.png",
                "cl": "png",
                "refId": "image_8",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 18.013,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 26.0182127489242,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1127.375,
                            457.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            48,
                            48,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            18,
                            18,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 4,
                "ty": 2,
                "nm": "Screenshot 2022-03-16 004724.png",
                "cl": "png",
                "parent": 7,
                "refId": "image_1",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            401.5,
                            389,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            2.174,
                            2.174,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 5,
                "ty": 4,
                "nm": "Shape Layer 28",
                "parent": 6,
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            445.625,
                            -22.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            446.625,
                            -21.25,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                363.25,
                                                -21.25
                                            ],
                                            [
                                                530,
                                                -21.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 6,
                "ty": 4,
                "nm": "Shape Layer 27",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1109,
                            444.988,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            353,
                            -47.012,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 12.008,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 20.0140098068648,
                                "s": [
                                    99.5,
                                    99.5,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "rc",
                                "d": 1,
                                "s": {
                                    "a": 0,
                                    "k": [
                                        183.5,
                                        78.024
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 5,
                                    "ix": 4
                                },
                                "nm": "Rectangle Path 1",
                                "mn": "ADBE Vector Shape - Rect",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        444.75,
                                        -8
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Rectangle 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 7,
                "ty": 4,
                "nm": "Shape Layer 226",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1090.625,
                            462.875,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 8.006,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 15.0105073551486,
                                "s": [
                                    92,
                                    92,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 25,
                        "nm": "Drop Shadow",
                        "np": 8,
                        "mn": "ADBE Drop Shadow",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 2,
                                "nm": "Shadow Color",
                                "mn": "ADBE Drop Shadow-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": [
                                        0.478431403637,
                                        0,
                                        0.858823597431,
                                        1
                                    ],
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Opacity",
                                "mn": "ADBE Drop Shadow-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 25.5,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Direction",
                                "mn": "ADBE Drop Shadow-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 135,
                                    "ix": 3
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Distance",
                                "mn": "ADBE Drop Shadow-0004",
                                "ix": 4,
                                "v": {
                                    "a": 0,
                                    "k": 1,
                                    "ix": 4
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Softness",
                                "mn": "ADBE Drop Shadow-0005",
                                "ix": 5,
                                "v": {
                                    "a": 0,
                                    "k": 59,
                                    "ix": 5
                                }
                            },
                            {
                                "ty": 7,
                                "nm": "Shadow Only",
                                "mn": "ADBE Drop Shadow-0006",
                                "ix": 6,
                                "v": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                }
                            }
                        ]
                    },
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 2,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "d": 1,
                                "ty": "el",
                                "s": {
                                    "a": 0,
                                    "k": [
                                        26.75,
                                        26.75
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "nm": "Ellipse Path 1",
                                "mn": "ADBE Vector Shape - Ellipse",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.752941236309,
                                        0.607843137255,
                                        0.796078491211,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        334.625,
                                        -30.375
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Ellipse 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 8.00560392274592,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 8,
                "ty": 4,
                "nm": "Shape Layer 25",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1044.25,
                            477,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            288.25,
                            -13.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                258.5,
                                                -0.25
                                            ],
                                            [
                                                277,
                                                -27.25
                                            ],
                                            [
                                                318,
                                                -27.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 1.5,
                                    "ix": 5
                                },
                                "lc": 2,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "d": [
                                    {
                                        "n": "d",
                                        "nm": "dash",
                                        "v": {
                                            "a": 0,
                                            "k": 2,
                                            "ix": 1
                                        }
                                    },
                                    {
                                        "n": "g",
                                        "nm": "gap",
                                        "v": {
                                            "a": 0,
                                            "k": 6,
                                            "ix": 2
                                        }
                                    },
                                    {
                                        "n": "o",
                                        "nm": "offset",
                                        "v": {
                                            "a": 1,
                                            "k": [
                                                {
                                                    "i": {
                                                        "x": [
                                                            0.833
                                                        ],
                                                        "y": [
                                                            0.833
                                                        ]
                                                    },
                                                    "o": {
                                                        "x": [
                                                            0.167
                                                        ],
                                                        "y": [
                                                            0.167
                                                        ]
                                                    },
                                                    "t": -39.027,
                                                    "s": [
                                                        990
                                                    ]
                                                },
                                                {
                                                    "t": 863.104172921045,
                                                    "s": [
                                                        146
                                                    ]
                                                }
                                            ],
                                            "ix": 7
                                        }
                                    }
                                ],
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    },
                    {
                        "ty": "tm",
                        "s": {
                            "a": 0,
                            "k": 0,
                            "ix": 1
                        },
                        "e": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.667
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.333
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 9.50665465826078,
                                    "s": [
                                        100
                                    ]
                                }
                            ],
                            "ix": 2
                        },
                        "o": {
                            "a": 0,
                            "k": 0,
                            "ix": 3
                        },
                        "m": 1,
                        "ix": 2,
                        "nm": "Trim Paths 1",
                        "mn": "ADBE Vector Filter - Trim",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            }
        ]
    },
    {
        "id": "comp_9",
        "layers": [
            {
                "ddd": 0,
                "ind": 1,
                "ty": 5,
                "nm": "ERITREA",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1139.285,
                            463.045,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 8.19999980926514,
                                    "f": "Mulish-Black",
                                    "t": "ERITREA",
                                    "j": 0,
                                    "tr": 25,
                                    "lh": 9.83999977111816,
                                    "ls": 0,
                                    "fc": [
                                        0.153,
                                        0.153,
                                        0.153
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 1,
                                "rn": 1,
                                "sh": 1,
                                "e": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 19.514,
                                            "s": [
                                                100
                                            ]
                                        },
                                        {
                                            "t": 29.5206644651256,
                                            "s": [
                                                0
                                            ]
                                        }
                                    ],
                                    "ix": 2
                                },
                                "r": 1
                            },
                            "a": {
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 2,
                "ty": 5,
                "nm": "Get standard regulatory intelligence in your inbox on- demand ",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1118.75,
                            485.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "hasMask": true,
                "masksProperties": [
                    {
                        "inv": false,
                        "mode": "a",
                        "pt": {
                            "a": 0,
                            "k": {
                                "i": [
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ]
                                ],
                                "o": [
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ]
                                ],
                                "v": [
                                    [
                                        166.25,
                                        -14.5
                                    ],
                                    [
                                        9.75,
                                        -14.5
                                    ],
                                    [
                                        -10.25,
                                        5.5
                                    ],
                                    [
                                        -10.25,
                                        16.5
                                    ],
                                    [
                                        9.75,
                                        36.5
                                    ],
                                    [
                                        166.25,
                                        36.5
                                    ],
                                    [
                                        186.25,
                                        16.5
                                    ],
                                    [
                                        186.25,
                                        5.5
                                    ]
                                ],
                                "c": true
                            },
                            "ix": 1
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 3
                        },
                        "x": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                        },
                        "nm": "Mask 1"
                    }
                ],
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 10,
                                    "f": "Mulish-Regular",
                                    "t": "Get standard regulatory\rintelligence in your inbox on-\rdemand ",
                                    "j": 0,
                                    "tr": 10,
                                    "lh": 12,
                                    "ls": 0,
                                    "fc": [
                                        0.463,
                                        0.263,
                                        0.522
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 50,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 3,
                                "rn": 0,
                                "sh": 2,
                                "o": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 20.014,
                                            "s": [
                                                -100
                                            ]
                                        },
                                        {
                                            "t": 41.0142099469629,
                                            "s": [
                                                100
                                            ]
                                        }
                                    ],
                                    "ix": 3
                                },
                                "r": 1
                            },
                            "a": {
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        22,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 20.0140098068648,
                "op": 61.5430801561093,
                "st": -38.5269688782148,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 3,
                "ty": 2,
                "nm": "icons8-eritrea-96.png",
                "cl": "png",
                "refId": "image_9",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 18.013,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 26.0182127489242,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1127.375,
                            457.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            48,
                            48,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            18,
                            18,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 4,
                "ty": 2,
                "nm": "Screenshot 2022-03-16 004724.png",
                "cl": "png",
                "parent": 7,
                "refId": "image_1",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            401.5,
                            389,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            2.174,
                            2.174,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 5,
                "ty": 4,
                "nm": "Shape Layer 28",
                "parent": 6,
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            445.625,
                            -22.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            446.625,
                            -21.25,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                363.25,
                                                -21.25
                                            ],
                                            [
                                                530,
                                                -21.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 6,
                "ty": 4,
                "nm": "Shape Layer 27",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1109,
                            444.988,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            353,
                            -47.012,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 12.008,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 20.0140098068648,
                                "s": [
                                    99.5,
                                    99.5,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "rc",
                                "d": 1,
                                "s": {
                                    "a": 0,
                                    "k": [
                                        183.5,
                                        78.024
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 5,
                                    "ix": 4
                                },
                                "nm": "Rectangle Path 1",
                                "mn": "ADBE Vector Shape - Rect",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        444.75,
                                        -8
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Rectangle 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 7,
                "ty": 4,
                "nm": "Shape Layer 226",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1090.625,
                            462.875,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 8.006,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 15.0105073551486,
                                "s": [
                                    92,
                                    92,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 25,
                        "nm": "Drop Shadow",
                        "np": 8,
                        "mn": "ADBE Drop Shadow",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 2,
                                "nm": "Shadow Color",
                                "mn": "ADBE Drop Shadow-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": [
                                        0.478431403637,
                                        0,
                                        0.858823597431,
                                        1
                                    ],
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Opacity",
                                "mn": "ADBE Drop Shadow-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 25.5,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Direction",
                                "mn": "ADBE Drop Shadow-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 135,
                                    "ix": 3
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Distance",
                                "mn": "ADBE Drop Shadow-0004",
                                "ix": 4,
                                "v": {
                                    "a": 0,
                                    "k": 1,
                                    "ix": 4
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Softness",
                                "mn": "ADBE Drop Shadow-0005",
                                "ix": 5,
                                "v": {
                                    "a": 0,
                                    "k": 59,
                                    "ix": 5
                                }
                            },
                            {
                                "ty": 7,
                                "nm": "Shadow Only",
                                "mn": "ADBE Drop Shadow-0006",
                                "ix": 6,
                                "v": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                }
                            }
                        ]
                    },
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 2,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "d": 1,
                                "ty": "el",
                                "s": {
                                    "a": 0,
                                    "k": [
                                        26.75,
                                        26.75
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "nm": "Ellipse Path 1",
                                "mn": "ADBE Vector Shape - Ellipse",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.752941236309,
                                        0.607843137255,
                                        0.796078491211,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        334.625,
                                        -30.375
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Ellipse 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 8.00560392274592,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 8,
                "ty": 4,
                "nm": "Shape Layer 25",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1044.25,
                            477,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            288.25,
                            -13.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                258.5,
                                                -0.25
                                            ],
                                            [
                                                277,
                                                -27.25
                                            ],
                                            [
                                                318,
                                                -27.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 1.5,
                                    "ix": 5
                                },
                                "lc": 2,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "d": [
                                    {
                                        "n": "d",
                                        "nm": "dash",
                                        "v": {
                                            "a": 0,
                                            "k": 2,
                                            "ix": 1
                                        }
                                    },
                                    {
                                        "n": "g",
                                        "nm": "gap",
                                        "v": {
                                            "a": 0,
                                            "k": 6,
                                            "ix": 2
                                        }
                                    },
                                    {
                                        "n": "o",
                                        "nm": "offset",
                                        "v": {
                                            "a": 1,
                                            "k": [
                                                {
                                                    "i": {
                                                        "x": [
                                                            0.833
                                                        ],
                                                        "y": [
                                                            0.833
                                                        ]
                                                    },
                                                    "o": {
                                                        "x": [
                                                            0.167
                                                        ],
                                                        "y": [
                                                            0.167
                                                        ]
                                                    },
                                                    "t": -39.027,
                                                    "s": [
                                                        990
                                                    ]
                                                },
                                                {
                                                    "t": 863.104172921045,
                                                    "s": [
                                                        146
                                                    ]
                                                }
                                            ],
                                            "ix": 7
                                        }
                                    }
                                ],
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    },
                    {
                        "ty": "tm",
                        "s": {
                            "a": 0,
                            "k": 0,
                            "ix": 1
                        },
                        "e": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.667
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.333
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 9.50665465826078,
                                    "s": [
                                        100
                                    ]
                                }
                            ],
                            "ix": 2
                        },
                        "o": {
                            "a": 0,
                            "k": 0,
                            "ix": 3
                        },
                        "m": 1,
                        "ix": 2,
                        "nm": "Trim Paths 1",
                        "mn": "ADBE Vector Filter - Trim",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            }
        ]
    },
    {
        "id": "comp_10",
        "layers": [
            {
                "ddd": 0,
                "ind": 1,
                "ty": 5,
                "nm": "NIGERIA",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1139.285,
                            463.045,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 8.19999980926514,
                                    "f": "Mulish-Black",
                                    "t": "NIGERIA",
                                    "j": 0,
                                    "tr": 25,
                                    "lh": 9.83999977111816,
                                    "ls": 0,
                                    "fc": [
                                        0.153,
                                        0.153,
                                        0.153
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 1,
                                "rn": 1,
                                "sh": 1,
                                "e": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 19.514,
                                            "s": [
                                                100
                                            ]
                                        },
                                        {
                                            "t": 29.5206644651256,
                                            "s": [
                                                0
                                            ]
                                        }
                                    ],
                                    "ix": 2
                                },
                                "r": 1
                            },
                            "a": {
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 2,
                "ty": 5,
                "nm": "View your compliance obligations in one click and speak to an expert",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1118.75,
                            485.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            0,
                            0,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "hasMask": true,
                "masksProperties": [
                    {
                        "inv": false,
                        "mode": "a",
                        "pt": {
                            "a": 0,
                            "k": {
                                "i": [
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ]
                                ],
                                "o": [
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        -11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        11.046
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        11.046,
                                        0
                                    ],
                                    [
                                        0,
                                        0
                                    ],
                                    [
                                        0,
                                        -11.046
                                    ]
                                ],
                                "v": [
                                    [
                                        166.25,
                                        -14.5
                                    ],
                                    [
                                        9.75,
                                        -14.5
                                    ],
                                    [
                                        -10.25,
                                        5.5
                                    ],
                                    [
                                        -10.25,
                                        16.5
                                    ],
                                    [
                                        9.75,
                                        36.5
                                    ],
                                    [
                                        166.25,
                                        36.5
                                    ],
                                    [
                                        186.25,
                                        16.5
                                    ],
                                    [
                                        186.25,
                                        5.5
                                    ]
                                ],
                                "c": true
                            },
                            "ix": 1
                        },
                        "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 3
                        },
                        "x": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                        },
                        "nm": "Mask 1"
                    }
                ],
                "t": {
                    "d": {
                        "k": [
                            {
                                "s": {
                                    "s": 10,
                                    "f": "Mulish-Regular",
                                    "t": "View your compliance obligations\rin one click and speak to an\rexpert",
                                    "j": 0,
                                    "tr": 10,
                                    "lh": 12,
                                    "ls": 0,
                                    "fc": [
                                        0.463,
                                        0.263,
                                        0.522
                                    ]
                                },
                                "t": 0
                            }
                        ]
                    },
                    "p": {},
                    "m": {
                        "g": 1,
                        "a": {
                            "a": 0,
                            "k": [
                                0,
                                0
                            ],
                            "ix": 2
                        }
                    },
                    "a": [
                        {
                            "nm": "Animator 1",
                            "s": {
                                "t": 0,
                                "xe": {
                                    "a": 0,
                                    "k": 50,
                                    "ix": 7
                                },
                                "ne": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 8
                                },
                                "a": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "b": 3,
                                "rn": 0,
                                "sh": 2,
                                "o": {
                                    "a": 1,
                                    "k": [
                                        {
                                            "i": {
                                                "x": [
                                                    0.833
                                                ],
                                                "y": [
                                                    0.833
                                                ]
                                            },
                                            "o": {
                                                "x": [
                                                    0.167
                                                ],
                                                "y": [
                                                    0.167
                                                ]
                                            },
                                            "t": 20.014,
                                            "s": [
                                                -100
                                            ]
                                        },
                                        {
                                            "t": 41.0142099469629,
                                            "s": [
                                                100
                                            ]
                                        }
                                    ],
                                    "ix": 3
                                },
                                "r": 1
                            },
                            "a": {
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        22,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "o": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                }
                            }
                        }
                    ]
                },
                "ip": 20.0140098068648,
                "op": 61.5430801561093,
                "st": -38.5269688782148,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 3,
                "ty": 2,
                "nm": "icons8-nigeria-96.png",
                "cl": "png",
                "refId": "image_10",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833
                                    ],
                                    "y": [
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167
                                    ],
                                    "y": [
                                        0.167
                                    ]
                                },
                                "t": 18.013,
                                "s": [
                                    0
                                ]
                            },
                            {
                                "t": 26.0182127489242,
                                "s": [
                                    100
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1127.375,
                            457.5,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            48,
                            48,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            18,
                            18,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 18.0126088261783,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 4,
                "ty": 2,
                "nm": "Screenshot 2022-03-16 004724.png",
                "cl": "png",
                "parent": 7,
                "refId": "image_1",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            401.5,
                            389,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            2.174,
                            2.174,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 5,
                "ty": 4,
                "nm": "Shape Layer 28",
                "parent": 6,
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            445.625,
                            -22.25,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            446.625,
                            -21.25,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                363.25,
                                                -21.25
                                            ],
                                            [
                                                530,
                                                -21.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 6,
                "ty": 4,
                "nm": "Shape Layer 27",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1109,
                            444.988,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            353,
                            -47.012,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 12.008,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 20.0140098068648,
                                "s": [
                                    99.5,
                                    99.5,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "rc",
                                "d": 1,
                                "s": {
                                    "a": 0,
                                    "k": [
                                        183.5,
                                        78.024
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 5,
                                    "ix": 4
                                },
                                "nm": "Rectangle Path 1",
                                "mn": "ADBE Vector Shape - Rect",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        444.75,
                                        -8
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Rectangle 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 12.0084058841189,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 7,
                "ty": 4,
                "nm": "Shape Layer 226",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1090.625,
                            462.875,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            334.625,
                            -30.375,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ],
                                    "y": [
                                        0.833,
                                        0.833,
                                        0.833
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ],
                                    "y": [
                                        0.167,
                                        0.167,
                                        0.167
                                    ]
                                },
                                "t": 8.006,
                                "s": [
                                    0,
                                    0,
                                    100
                                ]
                            },
                            {
                                "t": 15.0105073551486,
                                "s": [
                                    92,
                                    92,
                                    100
                                ]
                            }
                        ],
                        "ix": 6,
                        "x": "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 25,
                        "nm": "Drop Shadow",
                        "np": 8,
                        "mn": "ADBE Drop Shadow",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 2,
                                "nm": "Shadow Color",
                                "mn": "ADBE Drop Shadow-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": [
                                        0.478431403637,
                                        0,
                                        0.858823597431,
                                        1
                                    ],
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Opacity",
                                "mn": "ADBE Drop Shadow-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 25.5,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Direction",
                                "mn": "ADBE Drop Shadow-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 135,
                                    "ix": 3
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Distance",
                                "mn": "ADBE Drop Shadow-0004",
                                "ix": 4,
                                "v": {
                                    "a": 0,
                                    "k": 1,
                                    "ix": 4
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Softness",
                                "mn": "ADBE Drop Shadow-0005",
                                "ix": 5,
                                "v": {
                                    "a": 0,
                                    "k": 59,
                                    "ix": 5
                                }
                            },
                            {
                                "ty": 7,
                                "nm": "Shadow Only",
                                "mn": "ADBE Drop Shadow-0006",
                                "ix": 6,
                                "v": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                }
                            }
                        ]
                    },
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 2,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "d": 1,
                                "ty": "el",
                                "s": {
                                    "a": 0,
                                    "k": [
                                        26.75,
                                        26.75
                                    ],
                                    "ix": 2
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 3
                                },
                                "nm": "Ellipse Path 1",
                                "mn": "ADBE Vector Shape - Ellipse",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.752941236309,
                                        0.607843137255,
                                        0.796078491211,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "fl",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        1,
                                        1,
                                        1,
                                        1
                                    ],
                                    "ix": 4
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 5
                                },
                                "r": 1,
                                "bm": 0,
                                "nm": "Fill 1",
                                "mn": "ADBE Vector Graphic - Fill",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        334.625,
                                        -30.375
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Ellipse 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 8.00560392274592,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 8,
                "ty": 4,
                "nm": "Shape Layer 25",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1044.25,
                            477,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            288.25,
                            -13.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 0,
                        "k": [
                            100,
                            100,
                            100
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ind": 0,
                                "ty": "sh",
                                "ix": 1,
                                "ks": {
                                    "a": 0,
                                    "k": {
                                        "i": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "o": [
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ],
                                            [
                                                0,
                                                0
                                            ]
                                        ],
                                        "v": [
                                            [
                                                258.5,
                                                -0.25
                                            ],
                                            [
                                                277,
                                                -27.25
                                            ],
                                            [
                                                318,
                                                -27.25
                                            ]
                                        ],
                                        "c": false
                                    },
                                    "ix": 2
                                },
                                "nm": "Path 1",
                                "mn": "ADBE Vector Shape - Group",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.683090329638,
                                        0.319876966289,
                                        0.78431372549,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 1.5,
                                    "ix": 5
                                },
                                "lc": 2,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "d": [
                                    {
                                        "n": "d",
                                        "nm": "dash",
                                        "v": {
                                            "a": 0,
                                            "k": 2,
                                            "ix": 1
                                        }
                                    },
                                    {
                                        "n": "g",
                                        "nm": "gap",
                                        "v": {
                                            "a": 0,
                                            "k": 6,
                                            "ix": 2
                                        }
                                    },
                                    {
                                        "n": "o",
                                        "nm": "offset",
                                        "v": {
                                            "a": 1,
                                            "k": [
                                                {
                                                    "i": {
                                                        "x": [
                                                            0.833
                                                        ],
                                                        "y": [
                                                            0.833
                                                        ]
                                                    },
                                                    "o": {
                                                        "x": [
                                                            0.167
                                                        ],
                                                        "y": [
                                                            0.167
                                                        ]
                                                    },
                                                    "t": -39.027,
                                                    "s": [
                                                        990
                                                    ]
                                                },
                                                {
                                                    "t": 863.104172921045,
                                                    "s": [
                                                        146
                                                    ]
                                                }
                                            ],
                                            "ix": 7
                                        }
                                    }
                                ],
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Shape 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    },
                    {
                        "ty": "tm",
                        "s": {
                            "a": 0,
                            "k": 0,
                            "ix": 1
                        },
                        "e": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [
                                            0.667
                                        ],
                                        "y": [
                                            1
                                        ]
                                    },
                                    "o": {
                                        "x": [
                                            0.333
                                        ],
                                        "y": [
                                            0
                                        ]
                                    },
                                    "t": 0,
                                    "s": [
                                        0
                                    ]
                                },
                                {
                                    "t": 9.50665465826078,
                                    "s": [
                                        100
                                    ]
                                }
                            ],
                            "ix": 2
                        },
                        "o": {
                            "a": 0,
                            "k": 0,
                            "ix": 3
                        },
                        "m": 1,
                        "ix": 2,
                        "nm": "Trim Paths 1",
                        "mn": "ADBE Vector Filter - Trim",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 61.5430801561093,
                "st": -39.0273191233864,
                "bm": 0
            }
        ]
    },
    {
        "id": "comp_11",
        "layers": [
            {
                "ddd": 0,
                "ind": 1,
                "ty": 4,
                "nm": "Shape Layer 235",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 65.046,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 71.5500850595417,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 65.046,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 71.5500850595417,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 65.0455318723106,
                "op": 72.0504353047133,
                "st": 31.5220654458121,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 2,
                "ty": 4,
                "nm": "Shape Layer 234",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 58.541,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 65.0455318723106,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 58.541,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 65.0455318723106,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 58.5409786850796,
                "op": 65.5458821174822,
                "st": 25.017512258581,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 3,
                "ty": 4,
                "nm": "Shape Layer 233",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 52.036,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 58.5409786850796,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 52.036,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 58.5409786850796,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 52.0364254978485,
                "op": 59.0413289302512,
                "st": 18.5129590713499,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 4,
                "ty": 4,
                "nm": "Shape Layer 232",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 45.532,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 52.0364254978485,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 45.532,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 52.0364254978485,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 45.5318723106174,
                "op": 52.5367757430201,
                "st": 12.0084058841189,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 5,
                "ty": 4,
                "nm": "Shape Layer 231",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 39.027,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 45.5318723106174,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 39.027,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 45.5318723106174,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 39.0273191233864,
                "op": 46.032222555789,
                "st": 5.50385269688782,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 6,
                "ty": 4,
                "nm": "Shape Layer 230",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 32.523,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 39.0273191233864,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 32.523,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 39.0273191233864,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 32.5227659361553,
                "op": 39.527669368558,
                "st": -1.00070049034324,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 7,
                "ty": 4,
                "nm": "Shape Layer 229",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 26.018,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 32.5227659361553,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 26.018,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 32.5227659361553,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 26.0182127489242,
                "op": 33.0231161813269,
                "st": -7.5052536775743,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 8,
                "ty": 4,
                "nm": "Shape Layer 228",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 19.514,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 26.0182127489242,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 19.514,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 26.0182127489242,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 19.5136595616932,
                "op": 26.5185629940959,
                "st": -14.0098068648054,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 9,
                "ty": 4,
                "nm": "Shape Layer 227",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 13.009,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 19.5136595616932,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 13.009,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 19.5136595616932,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 13.0091063744621,
                "op": 20.0140098068648,
                "st": -20.5143600520364,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 10,
                "ty": 4,
                "nm": "Shape Layer 226",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 6.505,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 13.0091063744621,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 6.505,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 13.0091063744621,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 6.50455318723106,
                "op": 13.5094566196337,
                "st": -27.0189132392675,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 11,
                "ty": 4,
                "nm": "Shape Layer 225",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 0,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 6.50455318723106,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 0,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 6.50455318723106,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 7.00490343240268,
                "st": -33.5234664264986,
                "bm": 0
            }
        ]
    },
    {
        "id": "comp_12",
        "layers": [
            {
                "ddd": 0,
                "ind": 1,
                "ty": 4,
                "nm": "Shape Layer 235",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 65.046,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 71.5500850595417,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 65.046,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 71.5500850595417,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 65.0455318723106,
                "op": 72.0504353047133,
                "st": 31.5220654458121,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 2,
                "ty": 4,
                "nm": "Shape Layer 234",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 58.541,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 65.0455318723106,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 58.541,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 65.0455318723106,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 58.5409786850796,
                "op": 65.5458821174822,
                "st": 25.017512258581,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 3,
                "ty": 4,
                "nm": "Shape Layer 233",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 52.036,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 58.5409786850796,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 52.036,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 58.5409786850796,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 52.0364254978485,
                "op": 59.0413289302512,
                "st": 18.5129590713499,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 4,
                "ty": 4,
                "nm": "Shape Layer 232",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 45.532,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 52.0364254978485,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 45.532,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 52.0364254978485,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 45.5318723106174,
                "op": 52.5367757430201,
                "st": 12.0084058841189,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 5,
                "ty": 4,
                "nm": "Shape Layer 231",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 39.027,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 45.5318723106174,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 39.027,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 45.5318723106174,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 39.0273191233864,
                "op": 46.032222555789,
                "st": 5.50385269688782,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 6,
                "ty": 4,
                "nm": "Shape Layer 230",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 32.523,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 39.0273191233864,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 32.523,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 39.0273191233864,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 32.5227659361553,
                "op": 39.527669368558,
                "st": -1.00070049034324,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 7,
                "ty": 4,
                "nm": "Shape Layer 229",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 26.018,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 32.5227659361553,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 26.018,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 32.5227659361553,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 26.0182127489242,
                "op": 33.0231161813269,
                "st": -7.5052536775743,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 8,
                "ty": 4,
                "nm": "Shape Layer 228",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 19.514,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 26.0182127489242,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 19.514,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 26.0182127489242,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 19.5136595616932,
                "op": 26.5185629940959,
                "st": -14.0098068648054,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 9,
                "ty": 4,
                "nm": "Shape Layer 227",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 13.009,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 19.5136595616932,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 13.009,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 19.5136595616932,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 13.0091063744621,
                "op": 20.0140098068648,
                "st": -20.5143600520364,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 10,
                "ty": 4,
                "nm": "Shape Layer 226",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 6.505,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 13.0091063744621,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 6.505,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 13.0091063744621,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 6.50455318723106,
                "op": 13.5094566196337,
                "st": -27.0189132392675,
                "bm": 0
            },
            {
                "ddd": 0,
                "ind": 11,
                "ty": 4,
                "nm": "Shape Layer 225",
                "sr": 1,
                "ks": {
                    "o": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667
                                    ],
                                    "y": [
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333
                                    ],
                                    "y": [
                                        0
                                    ]
                                },
                                "t": 0,
                                "s": [
                                    100
                                ]
                            },
                            {
                                "t": 6.50455318723106,
                                "s": [
                                    0
                                ]
                            }
                        ],
                        "ix": 11
                    },
                    "r": {
                        "a": 0,
                        "k": 30,
                        "ix": 10
                    },
                    "p": {
                        "a": 0,
                        "k": [
                            1007,
                            505.062,
                            0
                        ],
                        "ix": 2
                    },
                    "a": {
                        "a": 0,
                        "k": [
                            413.25,
                            -121.75,
                            0
                        ],
                        "ix": 1
                    },
                    "s": {
                        "a": 1,
                        "k": [
                            {
                                "i": {
                                    "x": [
                                        0.667,
                                        0.667,
                                        0.667
                                    ],
                                    "y": [
                                        1,
                                        1,
                                        1
                                    ]
                                },
                                "o": {
                                    "x": [
                                        0.333,
                                        0.333,
                                        0.333
                                    ],
                                    "y": [
                                        0,
                                        0,
                                        0
                                    ]
                                },
                                "t": 0,
                                "s": [
                                    76,
                                    76,
                                    100
                                ]
                            },
                            {
                                "t": 6.50455318723106,
                                "s": [
                                    118,
                                    118,
                                    100
                                ]
                            }
                        ],
                        "ix": 6
                    }
                },
                "ao": 0,
                "ef": [
                    {
                        "ty": 5,
                        "nm": "Elastic: Scale",
                        "np": 5,
                        "mn": "Pseudo/MDS Elastic Controller",
                        "ix": 1,
                        "en": 1,
                        "ef": [
                            {
                                "ty": 0,
                                "nm": "Amplitude",
                                "mn": "Pseudo/MDS Elastic Controller-0001",
                                "ix": 1,
                                "v": {
                                    "a": 0,
                                    "k": 20,
                                    "ix": 1
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Frequency",
                                "mn": "Pseudo/MDS Elastic Controller-0002",
                                "ix": 2,
                                "v": {
                                    "a": 0,
                                    "k": 40,
                                    "ix": 2
                                }
                            },
                            {
                                "ty": 0,
                                "nm": "Decay",
                                "mn": "Pseudo/MDS Elastic Controller-0003",
                                "ix": 3,
                                "v": {
                                    "a": 0,
                                    "k": 60,
                                    "ix": 3
                                }
                            }
                        ]
                    }
                ],
                "shapes": [
                    {
                        "ty": "gr",
                        "it": [
                            {
                                "ty": "sr",
                                "sy": 2,
                                "d": 1,
                                "pt": {
                                    "a": 0,
                                    "k": 6,
                                    "ix": 3
                                },
                                "p": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 4
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "or": {
                                    "a": 0,
                                    "k": 14.32,
                                    "ix": 7
                                },
                                "os": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 9
                                },
                                "ix": 1,
                                "nm": "Polystar Path 1",
                                "mn": "ADBE Vector Shape - Star",
                                "hd": false
                            },
                            {
                                "ty": "st",
                                "c": {
                                    "a": 0,
                                    "k": [
                                        0.478431402468,
                                        0,
                                        0.85882358925,
                                        1
                                    ],
                                    "ix": 3
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 4
                                },
                                "w": {
                                    "a": 0,
                                    "k": 0.5,
                                    "ix": 5
                                },
                                "lc": 1,
                                "lj": 1,
                                "ml": 4,
                                "bm": 0,
                                "nm": "Stroke 1",
                                "mn": "ADBE Vector Graphic - Stroke",
                                "hd": false
                            },
                            {
                                "ty": "tr",
                                "p": {
                                    "a": 0,
                                    "k": [
                                        413.25,
                                        -121.75
                                    ],
                                    "ix": 2
                                },
                                "a": {
                                    "a": 0,
                                    "k": [
                                        0,
                                        0
                                    ],
                                    "ix": 1
                                },
                                "s": {
                                    "a": 0,
                                    "k": [
                                        100,
                                        100
                                    ],
                                    "ix": 3
                                },
                                "r": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 6
                                },
                                "o": {
                                    "a": 0,
                                    "k": 100,
                                    "ix": 7
                                },
                                "sk": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 4
                                },
                                "sa": {
                                    "a": 0,
                                    "k": 0,
                                    "ix": 5
                                },
                                "nm": "Transform"
                            }
                        ],
                        "nm": "Polystar 1",
                        "np": 3,
                        "cix": 2,
                        "bm": 0,
                        "ix": 1,
                        "mn": "ADBE Vector Group",
                        "hd": false
                    }
                ],
                "ip": 0,
                "op": 7.00490343240268,
                "st": -33.5234664264986,
                "bm": 0
            }
        ]
    }
];

animationData.assets = assets;

export { animationData };
