import React from 'react';
import { NavLink } from 'react-router-dom';


import classNames from 'classnames';

import styles from './Pressable.module.scss';


const A = ( {
    className,
    children,
    href = "/",
    outline,
    isExt = false,
    ...props
} ) =>
{

    if ( isExt )
    {
        return ( <a
            className={ classNames( styles.pressable,
                className ) }
            href={ href }
            target="_blank"
            rel="noopener noreferrer"
            { ...props }>
            { children }
        </a> );
    }

    return (
        <NavLink
            to={ href }
            className={ classNames( styles.pressable, className ) }
            { ...props } >
            { children }
        </NavLink>
    );
};

export { A };
