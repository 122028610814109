import styles from "./Footer.module.scss";
import { Container } from "../Container";
import linkedin from "../../assets/images/linkedin.svg";
import twitter from "../../assets/images/twitter.svg";
import letter from "../../assets/images/letter.svg";
import phone from "../../assets/images/phone.svg";
import { NavLink } from "react-router-dom";


const Footer = ({ stylee, stylee2 }) => {


  return (
    <div style={stylee}>
      <div className={`${styles.wrapper} ${stylee2}`}>
        <Container className={styles.row}>
          <a
            href="https://wa.link/v88hjq"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.chat}
          >
            We are just one call away
          </a>
          <div className={styles.links}>
            <NavLink to="/privacy">Terms of service</NavLink>
            <NavLink to="/privacy">Privacy Policy</NavLink>
            <NavLink to="/privacy">Cookie Policy</NavLink>
          </div>

          <div className={styles.icons}>
            <div className={styles.icon}>
            <a href="https://wa.link/04hgpe"  >
            <img src={phone} alt={`${phone} icon`} />
            </a>
             
            </div>
            <div className={styles.icon}>
            <a href="mailto:admin@regcompass.com"  >
            <img src={letter} alt={`${letter} icon`} />
            </a>            
            </div>

            <div className={styles.icon}>
            <a href="https://ng.linkedin.com/company/regcompass-consults"  >
            <img src={linkedin} alt={`${linkedin} icon`} />
            </a>
              
            </div>
            <div className={styles.icon}>
            <a href="https://twitter.com/Regcompass_NG?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor
"  >
            <img src={twitter} alt={`${twitter} icon`} />
            </a>
            </div>
          </div>
        </Container>
      </div>

      <footer>
        <Container>
          <p>With ❤️ from StartButton</p>
        </Container>
      </footer>
    </div>
  );
};
export { Footer };
