import React from 'react';

const Menu = () => 
{
    return (
        <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="7.33323" height="1.33331" fill="white" />
            <rect y="3.33337" width="15.3331" height="1.33331" fill="white" />
            <rect y="6.66663" width="12.6665" height="1.33331" fill="white" />
        </svg>

    );
};

export { Menu };
