import React from 'react';
import classNames from 'classnames';
import { Container } from '../../components';


import styles from './Solutions.module.scss';


const Solutions = ( { className, children } ) =>
{
    return (
        <section className={ classNames( styles.wrapper, className ) } >
            { children }
        </section>
    );
};


const SolutionsTitle = ( { className } ) =>
{
    return (

        <h1 className={ classNames( styles.title, className ) } >
            <Container>
                <p>SOLUTIONS</p>
            </Container>
        </h1>
    );
};

const SolutionsRow = ( { className, children } ) =>
{
    return (
        <Solutions >
            <div className={ styles.row }>
                { children }
            </div>
        </Solutions>
    );
};

export
{
    SolutionsTitle,
    Solutions,
    SolutionsRow
};
