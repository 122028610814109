import React from 'react';
import Lottie from "lottie-react";

import { animationData } from "./formatted";

const options = {
    animationData,
    loop: true,
    autoplay: true,
};

const Map = () =>
{
    return (
        <Lottie { ...options } />
    );
};

export { Map };
