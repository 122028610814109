import React from "react";

import { NavBar2, Container, Button, A } from "../components";

import styles from "./Started.module.scss";

const Started = () =>
{
  const onSubmit = ( e ) =>
  {
    e.preventDefault();
    e.target.reset();
  };

  return (
    <div style={ { maxWidth: "100vw", maxHeight: "100vh", overflow: "hidden" } }>
      <div className={ styles.started }>
        <NavBar2 />

        <Container className={ styles.wrapper }>
          <h4 className={ styles.headerText }>
            We are <span className={ styles.purple }> launching </span>our Digital
            Product <span className={ styles.purple }> Soon </span>{ " " }
          </h4>

          <p className={ styles.motto }>
            {/* Would you like to be part of the very firsts to be notified when we
            are about to launch */}
            Be the first to know when it launches
          </p>

          <form onSubmit={ onSubmit }>
            {/* <input type="email" name="" id="" /> */}
            
            <Button href="mailto:admin@regcompass.com">
              <a href="mailto:admin@regcompass.com" style={ {color: "white"}}>
                Start Here, Let's Get You Started  🎉
              </a>
            </Button>
            
          </form>
        </Container>
      </div>
    </div>
  );
};

export default Started;
