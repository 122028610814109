import React, { useState } from "react";
import { useSearchParams , Routes, Route} from "react-router-dom";
import { Mousewheel, Keyboard } from "swiper";
import
{
  Home,
  WhatYouGet,
  SolutionsCard,
  Solutions,
  SolutionsTitle,
  SolutionsRow,
  Testimonial,
  FAQ,
  Blog,
} from "./sections";
import { NavBar } from "./components";
import styles from "./App.module.scss";

function App ()
{
  const [ dims, setDims ] = useState( {
    width: window.innerWidth,
    height: window.innerHeight,
  } );

  const [ searchParams ] = useSearchParams();
  const [ isFirst, setIsFirst ] = useState( true );
  const [ swiper, setSwiper ] = useState( null );
  const [ navIsVisible, setNavIsVisible ] = useState( false );
  const [ bgState, setBgState ] = useState( {
    isLilac: false,
    isBlack: false,
    isWhite: true,
  } );

  const jumpTo = ( index ) =>
  {
    swiper.slideTo( index );
  };

  const handleScroll = () =>
  {

  };

  if ( dims.width >= 1024 )
  {
    return (

      <div>
        <div>
          <NavBar
            { ...bgState }
            visible={ navIsVisible }
            setVisible={ setNavIsVisible }
            isFirst={ isFirst }
            jumpTo={ jumpTo }
          />
          <div
            spaceBetween={ 0 }
            slidesPerView={ 1 }
            direction="vertical"
            height={ dims.height }
            observer={ true }
            mousewheel={ true }
            keyboard={ { enabled: true } }
            speed={ 500 }
            updateOnWindowResize={ true }
            onSwiper={ ( swiper ) => setSwiper( swiper ) }
            onSlideChange={ handleScroll }
            modules={ [ Mousewheel, Keyboard ] }
          >

                <section id="home">
                  <>
                    <div>               
                    <Home />
                    </div>
                  </>
                </section>  
         
                <section id="resources">
                  <>
                    <div>               
                    <WhatYouGet />
                    </div>
                  </>
                  <>
                    <div>
                      <SolutionsTitle />
                      <SolutionsRow>
                        <SolutionsCard index={ 0 } />
                        <SolutionsCard index={ 1 } />
                      </SolutionsRow>
                    </div>
                  </>
                </section>
               


           
            

            <section id="solutions">
              <>
                <div>
                  <SolutionsTitle />
                  <SolutionsRow>
                    <SolutionsCard index={ 2 } />
                    <SolutionsCard index={ 3 } />
                  </SolutionsRow>
                </div>
              </>
              <>
              <div>
                <SolutionsTitle />
                <SolutionsRow>
                  <SolutionsCard className={ styles.up } index={ 4 } />
                  <SolutionsCard className={ styles.down } index={ 5 } />
                </SolutionsRow>
              </div>
            </>
          </section>



          <section id="about">
            <>
              <div>
                <Testimonial />
              </div>
            </>
          </section>
            
            
            <>
              <di>
                <FAQ />
              </di>
            </>
            <>
              <div>
                <Blog width={ dims.width } />
              </div>
            </>
          </div>
        </div>
      </div>

    );
  }

  return (
    <div>
      <div onWheel={ handleScroll }>
        <NavBar
          { ...bgState }
          visible={ navIsVisible }
          isFirst={ isFirst }
          setVisible={ setNavIsVisible }
          jumpTo={ jumpTo }
        />

        <div
          spaceBetween={ 0 }
          slidesPerView={ 1 }
          direction="vertical"
          height={ dims.height }
          observer={ true }
          mousewheel={ true }
          keyboard={ { enabled: true } }
          speed={ 500 }
          updateOnWindowResize={ true }
          onSwiper={ ( swiper ) => setSwiper( swiper ) }
          onSlideChange={ handleScroll }
          modules={ [ Mousewheel, Keyboard ] }
        >
          <div>
            <div>
                <section id="home">
                  <>
                    <div>               
                    <Home />
                    </div>
                  </>
                </section> 
            </div>
          </div>


          <section id="resources">
          <>
            <div>
              <WhatYouGet />
            </div>
          </>
          <>
            <div>
              <SolutionsTitle />
              <Solutions>
                <SolutionsCard index={ 0 } />
              </Solutions>
            </div>
          </>
           </section> 


          <section id="solutions">
          <>
            <div>
              <SolutionsTitle />
              <Solutions>
                <SolutionsCard index={ 1 } />
              </Solutions>
            </div>
          </>
          <>
            <div>
              <SolutionsTitle />
              <Solutions>
                <SolutionsCard index={ 2 } />
              </Solutions>
            </div>
          </>
          <>
            <div>
              <SolutionsTitle />
              <Solutions>
                <SolutionsCard index={ 3 } />
              </Solutions>
            </div>
          </>
          <>
            <div>
              <SolutionsTitle />
              <Solutions>
                <SolutionsCard index={ 4 } />
              </Solutions>
            </div>
          </>
          <>
            <div>
              <SolutionsTitle />
              <Solutions>
                <SolutionsCard index={ 5 } />
              </Solutions>
            </div>
          </>
          </section>  






          <section id="about">
          <>
            <div>
              <Testimonial />
            </div>
          </>
          </section>
         
          
          <>
            <div>
              <FAQ />
            </div>
          </>
          <>
            <div>
              <Blog width={ dims.width } />
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default App;
