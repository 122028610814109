import blogimg1 from "../../assets/images/blogimg1.png";
import blogimg2 from "../../assets/images/blogimg2.png";
import blogimg3 from "../../assets/images/blogimg3.png";

export const blogPosts = [
    {
        img: blogimg1,
        title: "All you need to know about the eNaira",
        date: "2ND DEC 2021",
    },
    {
        img: blogimg2,
        title:
            "A Primer Lending and BNPL outlook in selected african jurisdictions",
        date: "2ND DEC 2021",
    },
    {
        img: blogimg3,
        title: "Want to know more about PAPSS and how we can provide support?",
        date: "2ND DEC 2021",
    }
];
