import React, { useState } from 'react';
import classNames from 'classnames';

import { Container } from '../../components';
import { faqs } from './questions';
import styles from './FAQ.module.scss';


const FAQ = () =>
{
  const [ activeIndex, setActiveIndex ] = useState( 0 );

  const handleActiveIndex = ( e ) =>
  {
    const index = e.target.dataset.index;

    setActiveIndex( +index - 1 );
  };

  return (
    <section className={ styles.faq }>
      <Container className={ styles.wrapper }>


        <h1 className={ styles.title }>F.A.Q</h1>
        <p className={ styles.sub }>Incase you were wondering</p>

        <div className={ styles.accordionLarge }>
          <div>
            {
              faqs.map( q => ( <button
                className={ classNames( styles.panel,
                  {
                    [ styles.active ]: q.id === ( activeIndex + 1 )
                  } ) }
                data-index={ q.id }
                key={ q.id }
                onClick={ handleActiveIndex }
              >
                { q.question }
              </button> ) )
            }
          </div>

          <div className={ styles.answer }>
            { faqs[ activeIndex ]?.answer }
          </div>
        </div>

        <div className={ styles.accordionMobile }>
          {
            faqs.map( q => ( <article key={ q.id }
            >
              <button
                className={ classNames( styles.panel,
                  {
                    [ styles.active ]: q.id === ( activeIndex + 1 )
                  } ) }
                data-index={ q.id }
                onClick={ handleActiveIndex }
              >
                { q.question }
              </button>
              <div className={ classNames( styles.animatedPanel,
                {
                  [ styles.full ]: q.id === ( activeIndex + 1 )
                } ) }>
                { q.answer }
              </div>
            </article> ) )
          }
        </div>



      </Container>
    </section>
  );
};

export { FAQ };
